import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import Router from 'components/Router'
import ContextMenu from 'components/ContextMenu'
import Panel from 'components/Panel'
import Modal from 'components/Modal'

import GlobalStyle from './GlobalStyle'

const App = () => {
  const dispatch = store.useAppDispatch()
  const { fontSize } = useSelector(store.selectSettings)

  const saveLocalState = async () => {
    await dispatch(store.saveProject())
  }

  useEffect(() => {
    window.addEventListener('beforeunload', saveLocalState)
    return () => window.removeEventListener('beforeunload', saveLocalState)
  }, [])

  return (
    <ThemeProvider theme={{ ...theme, fontSize }}>
      <GlobalStyle $fontSize={fontSize} />
      <Router />
      <ContextMenu />
      <Panel />
      <Modal />
    </ThemeProvider>
  )
}

export default App
