import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import theme from 'theme'
import * as store from 'store'
import { localeOptions } from 'utils/locale'
import Page from 'components/Page'
import Button, { FormattedLinkButton } from 'components/Button'

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const SelectLanguage = styled.div`
  color: ${theme.palette.primary.xxxlight};
  font-size: ${props => props.theme.fontSize * 1.4}px;
`

const Languages = styled.div`
  display: flex;
  flex-direction: column;  
  margin: ${theme.spacing.unit}px 0;
`

const LanguageButton = styled((props) => <Button {...props} $variant={props.variant} />)`
  padding: ${theme.spacing.unit}px;
  margin: ${theme.spacing.unit / 4}px;
`

const CancelButtonSection = styled.div`
  margin: auto;
  padding: ${theme.spacing.unit}px;
`

const AddTutorialsPage = () => {
  const history = useHistory()
  const dispatch = store.useAppDispatch()

  const addTutorials = (locale: string) => () => {
    dispatch(store.addTutorials(locale))
    history.push('/projects')
  }

  return (
    <Page>
      <Centered>
        <FormattedMessage id={'AddTutorialsPage.title'} tagName={'h1'} />
        <FormattedMessage id={'AddTutorialsPage.message1'} tagName={'p'} />
        <FormattedMessage id={'AddTutorialsPage.message2'} tagName={'p'} />

        <Languages>
          <SelectLanguage>
            <FormattedMessage id={'AddTutorialsPage.languageSelect'} tagName={'p'} />
          </SelectLanguage>

          {localeOptions.map(({ value, label }) => (
            <LanguageButton
              data-testid={`AddTutorials-${value}`}
              key={value}
              onClick={addTutorials(value)}
              variant={'primaryInverse'}
            >
              {label}
            </LanguageButton>
          ))}
        </Languages>

        <CancelButtonSection>
          <FormattedLinkButton id={'Buttons.cancel'} to={'/projects'} $variant={'secondary'} />
        </CancelButtonSection>
      </Centered>
    </Page>
  )
}

export default AddTutorialsPage
