import { useEffect } from 'react'
import styled from 'styled-components'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'

import { Coords } from 'types'
import * as store from 'store'
import { CommandButton } from 'components/CommandBar'
import { getPointFromEvent } from 'utils/mouse'

import ZoomListbox from './ZoomListbox'

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`

const ZoomPicker = () => {
  const dispatch = store.useAppDispatch()

  const zoomOut = () => dispatch(store.zoomOut())
  const zoomIn = () => dispatch(store.zoomIn())
  const resetZoom = () => dispatch(store.resetZoom())
  const updateZoom = ({ delta, pointer }: { delta: number, pointer: Coords }) => dispatch(store.updateZoom({ delta, pointer }))

  const onKeydown = (event: KeyboardEvent) => {
    if (!event.ctrlKey) return

    const keys = {
      '-': zoomOut,
      '=': zoomIn,
      '+': zoomIn,
      '0': resetZoom,
    }

    const key = event.key.toLowerCase() as keyof typeof keys

    if (keys[key]) {
      event.preventDefault()
      keys[key]()
    }
  }

  const onWheel = (event: WheelEvent) => {
    if (!event.ctrlKey) return

    event.preventDefault()
    updateZoom({ delta: - event.deltaY / 1000, pointer: getPointFromEvent(event) })
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeydown)
    window.addEventListener('wheel', onWheel, { passive: false })
    return () => {
      window.removeEventListener('keydown', onKeydown)
      window.removeEventListener('wheel', onWheel)
    }
  }, [])

  return (
    <Wrapper data-testid={'ZoomPicker'}>
      <CommandButton data-testid={'ZoomPicker-zoom-out'} onClick={zoomOut} title={'Zoom out'}>
        <IoIosRemove />
      </CommandButton>

      <ZoomListbox />

      <CommandButton data-testid={'ZoomPicker-zoom-in'} onClick={zoomIn} title={'Zoom in'}>
        <IoIosAdd />
      </CommandButton>
    </Wrapper>
  )
}

export default ZoomPicker
