import { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import theme from 'theme'
import * as store from 'store'

import BaseCard from './BaseCard'
import CategoryList from './CategoryList'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

const BaseCards = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.spacing.unit / 2}px;
`

const BaseCardCommandPanel = () => {
  const baseCardsByCategory = useSelector(store.selectBaseCardsByCategory)

  const categories = Object.keys(baseCardsByCategory)
  const [selected, setSelected] = useState(categories[0])

  return (
    <Wrapper data-testid={'BaseCardCommandPanel'}>
      <CategoryList categories={categories} selected={selected} setSelected={setSelected} />
      <BaseCards>
        {baseCardsByCategory[selected]?.map((baseCard) => <BaseCard key={baseCard.id} baseCard={baseCard} />)}
      </BaseCards>
    </Wrapper>
  )
}

export default BaseCardCommandPanel