import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import Page from 'components/Page'

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
`

const ErrorPage = () => {
  const location = useLocation()
  const message = String((location.state as any)?.error) || ''

  return (
    <Page>
      <Centered>
        <FormattedMessage id={'ErrorPage.title'} tagName={'h1'} />
        {message}
      </Centered>
    </Page>
  )
}

export default ErrorPage
