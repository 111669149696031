export enum buttons {
  'left',
  'middle',
  'right',
}

export const getPointFromEvent = (event: any) => {
  var point = { x: 0, y: 0 }

  if (event.targetTouches) {
    point.x = event.targetTouches[0].clientX
    point.y = event.targetTouches[0].clientY
  } else {
    point.x = event.clientX
    point.y = event.clientY
  }

  return point
}