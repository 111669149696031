import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { IoIosClose } from 'react-icons/io'

import { ToastVariant } from 'types'
import theme from 'theme'
import * as store from 'store'

type ToastProps = {
  id: string | number
  removeToast: (id: string | number) => any
}

const Wrapper = styled.div<{ variant: ToastVariant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ variant }) => variant === 'success' ? theme.palette.primary.inverse : theme.palette.secondary.main};
  color: ${({ variant }) => variant === 'success' ? theme.palette.primary.main : theme.palette.secondary.inverse};
  padding: ${theme.spacing.unit / 2}px;
  margin-bottom: ${theme.spacing.unit / 2}px;
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const Toast = ({ id, removeToast }: ToastProps) => {
  const toast = useSelector(store.toastByIdSelector(id))

  const onDismiss = () => removeToast(id)

  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss()
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!toast) return null

  const { text, variant } = toast

  return (
    <Wrapper data-testid={`Toast-${id}`} variant={variant}>
      {text}

      <CloseButton data-testid={`Toast-${id}-dismiss`} onClick={onDismiss} aria-label={'close'}>
        <IoIosClose />
      </CloseButton>
    </Wrapper>
  )
}

export default Toast
