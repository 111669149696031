import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { RiEdit2Line, RiDeleteBin2Line, RiFileCopy2Line, RiUploadLine } from 'react-icons/ri'

import { IToast, IProjectSummary } from 'types'
import theme from 'theme'
import * as store from 'store'
import { RootState } from 'store/rootReducer'

import EmptyProjectList from './EmptyProjectList'

const { actionSize } = theme.projectList

const Actions = styled.div`
  display: none;
  margin: auto;
  padding: 0 ${theme.spacing.unit / 2}px;
`

const ActionStyle = css`
  background: transparent;
  border: 1px solid transparent;
  color: ${theme.palette.secondary.inverse};

  &:hover {
    cursor: pointer;
    color: ${theme.palette.secondary.light};
  }

  &:disabled {
    color: ${theme.palette.disabled.main};
  }
`

const ActionButton = styled.button`
  ${ActionStyle}
`

const ActionLink = styled(Link)`
  ${ActionStyle}
  padding: 1px 6px;
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.primary.xlight};
  }

  &:hover {
    background: ${theme.palette.primary.main};
    cursor: pointer;

    ${Actions} {
      display: flex;
    }
  }
`

const ProjectName = styled(Link)`
  flex: 1;
  padding: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
  color: ${theme.palette.primary.inverse};
  text-decoration: none;

  &:hover {
    color: ${theme.palette.secondary.light};
  }
`

const ProjectList = () => {
  const dispatch = store.useAppDispatch()
  const allProjects = useSelector((state: RootState) => store.selectAllProjects(state))

  const addToast = (toast: Omit<IToast, 'id'>) => dispatch(store.addToast(toast))
  const deleteProject = (id: string) => dispatch(store.deleteProject(id))
  const copyProject = (id: string) => dispatch(store.copyProject(id))
  const exportProject = (id: string) => dispatch(store.exportProject(id))

  const onDelete = async (id: string, name: string) => {
    if (window.confirm(`Are you sure you want to delete project ${name}?`)) {
      const res: any = await deleteProject(id)

      if (res.error) {
        addToast({ text: `Failed to delete - ${String(res.error)}`, variant: 'error' })
      }
    }
  }

  if (!allProjects.length) {
    return (
      <EmptyProjectList />
    )
  }

  return (
    <List data-testid={'ProjectList'}>
      {(allProjects as IProjectSummary[]).map(({ id, name }) => (
        <ListItem key={id} data-testid={`ProjectList-item-${id}`}>
          <ProjectName to={`/projects/${id}`}>{name}</ProjectName>

          <Actions>
            <ActionButton data-testid={`ProjectList-export-${id}`} title={`Export ${name}`} onClick={() => exportProject(id)}>
              <RiUploadLine size={actionSize} />
            </ActionButton>
            <ActionButton data-testid={`ProjectList-copy-${id}`} title={`Copy ${name}`} onClick={() => copyProject(id)}>
              <RiFileCopy2Line size={actionSize} />
            </ActionButton>
            <ActionLink data-testid={`ProjectList-edit-${id}`} title={`Edit ${name}`} to={`/projects/${id}/edit`}>
              <RiEdit2Line size={actionSize} />
            </ActionLink>
            <ActionButton data-testid={`ProjectList-delete-${id}`} title={`Delete ${name}`} onClick={() => onDelete(id, name)}>
              <RiDeleteBin2Line size={actionSize} />
            </ActionButton>
          </Actions>
        </ListItem>
      ))}
    </List>
  )
}

export default ProjectList
