import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

import theme from 'theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 20%;
  left: 0;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${spin} 1s infinite linear;
  height: 60px;
  width: 60px;
  margin: auto;
  border: 6px solid ${rgba(theme.palette.secondary.dark, 0.1)};
  border-top: 6px solid ${rgba(theme.palette.secondary.light, 0.8)};
  border-radius: 50%;
`

const Loading = () => {
  return (
    <Wrapper data-testid={'Loading'}>
      <Spinner />
    </Wrapper>
  )
}

export default Loading
