import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { ICard, FlooredNumber } from 'types'
import theme from 'theme'
import * as store from 'store'
import { getCardQuote } from 'utils/cardUtils'
import { RootState } from 'store/rootReducer'
import Button from 'components/Button'
import Form, { TextField } from 'components/Form'
import { preventEvent } from 'utils'

type AddCardModalProps = {
  baseCardId: string
}

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Card = styled.div`
  flex: 1 200px;
  padding: ${theme.spacing.unit / 3}px ${theme.spacing.unit}px
    ${theme.spacing.unit}px 0;
`

const Title = styled.h2`
  font-weight: bold;
  display: flex;
  padding-bottom: ${theme.spacing.unit}px;
  margin: 0;
`

const Description = styled.div`
  margin: ${theme.spacing.unit}px 0;
  font-size: 18px;
  font-weight: lighter;
`

const Quote = styled.div`
  font-style: italic;
  padding-bottom: ${theme.spacing.unit}px;
`

const Image = styled.img`
  width: 100%;
`

const Inputs = styled.div`
  flex: 1 200px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const AddCardModal = ({ baseCardId }: AddCardModalProps) => {
  const dispatch = store.useAppDispatch()
  const zoom = useSelector(store.selectZoom)
  const { stageX, stageY } = useSelector(store.selectStageCoord)
  const dimensions = useSelector(store.selectDimensions)
  const baseCard = useSelector((state: RootState) => store.selectBaseCardById(state, baseCardId))
  const quoteId = useSelector(store.selectRandomQuoteIndex(baseCardId))
  
  useEffect(() => {
    if (!baseCard) dispatch(store.hideModal())
  }, [baseCard])

  if (!baseCard) return null

  const { id, title, quotes, placeholders, imageSrc, description, priority } = baseCard
  const maxLength = Math.max(placeholders.name.length, placeholders.notes.length)
  const placeholderIndex = Math.floor(Math.random() * maxLength)
  const quote = getCardQuote(quotes, quoteId)

  const hideModal = () => dispatch(store.hideModal())
  const resetLayout = () => dispatch(store.resetLayout())
  const addCard = (card: Partial<ICard>) => dispatch(store.addCard(card))
  const onClickCancel = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    hideModal()
  }

  const onSubmit = (values: { name: string; notes: string }) => {
    const x = Math.floor(stageX + (dimensions.width / 2 / zoom) - (theme.card.width / 2)) as FlooredNumber
    const y = Math.floor(stageY + (dimensions.height / 2 / zoom) - (theme.card.height / 2)) as FlooredNumber
    addCard({ ...values, quoteId, baseId: id, x, y, priority })
    resetLayout()
  }

  return (
    <Form
      enableReinitialize
      formName={'AddCardModal'}
      initialValues={{ name: '', notes: '' }}
      onSubmit={onSubmit}
    >
      <Title>{title}</Title>

      <Flex>
        <Card>
          {quote && <Quote>{quote}</Quote>}
          {description && <Description>{description}</Description>}
          {imageSrc && <Image src={imageSrc} />}
        </Card>

        <Inputs>
          <TextField
            name={'name'}
            label={'Name / Description'}
            as={'textarea'}
            rows={3}
            placeholder={
              placeholders.name[placeholderIndex % placeholders.name.length]
            }
          />
          <TextField
            name={'notes'}
            label={'Notes'}
            as={'textarea'}
            rows={7}
            placeholder={
              placeholders.notes[placeholderIndex % placeholders.notes.length]
            }
          />
        </Inputs>
      </Flex>

      <Buttons>
        <Button
          data-testid={'AddCardModal-cancel'}
          $variant={'secondary'}
          onPointerDown={preventEvent}
          onPointerUp={onClickCancel}
        >
          <FormattedMessage id={'Buttons.cancel'} />
        </Button>
        <Button
          data-testid={'AddCardModal-add'}
          $variant={'primaryInverse'}
          type={'submit'}
        >
          <FormattedMessage id={'Buttons.add'} />
        </Button>
      </Buttons>
    </Form>
  )
}

export default AddCardModal
