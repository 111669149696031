import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { IBaseCard } from 'types'
import theme from 'theme'
import * as store from 'store'
import { getCardQuote } from 'utils/cardUtils'

type BaseCardProps = {
  baseCard: IBaseCard
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.unit / 2}px;
  width: ${theme.card.width}px;
  border: 5px solid ${theme.palette.primary.inverse};
  background: ${theme.palette.primary.dark};
  cursor: pointer;
  height: ${theme.card.height}px;
  padding: ${theme.card.padding}px;
  
  &:hover {
    border-color: ${theme.palette.secondary.dark};
  }

  &:active {
    border-color: ${theme.palette.secondary.light};
  }
`

const Title = styled.div`
  height: ${theme.card.titleHeight}px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Quote = styled.div<{ $fontSize: number }>`
  height: ${theme.card.quoteHeight}px;
  font-size: ${props => Math.floor(props.$fontSize * 0.8)}px;
  line-height: 1.2;
  font-style: italic;
  overflow: hidden;
  margin: auto 0;
`

const Image = styled.img`
  width: 100%;
`

const BaseCard = ({ baseCard }: BaseCardProps) => {
  const dispatch = store.useAppDispatch()
  const fontSize = Number(useSelector(store.selectFontSize))
  const quoteId = useSelector(store.selectRandomQuoteIndex(baseCard.id))

  const { title, quotes, imageSrc } = baseCard
  const quote = getCardQuote(quotes, quoteId)

  const showAddCardModal = () => dispatch(store.showModal({ name: 'AddCardModal', props: { baseCardId: baseCard.id } }))

  return (
    <Wrapper data-testid={`BaseCard-${baseCard.id}`} onPointerUp={showAddCardModal}>
      <Title>{title}</Title>
      {quote && <Quote $fontSize={fontSize}>{quote}</Quote>}
      {imageSrc && <Image src={imageSrc} />}
    </Wrapper>
  )
}

export default BaseCard