import { useSelector } from 'react-redux'
import styled from 'styled-components'

import * as store from 'store'
import { useMousePosition } from 'hooks'

const Wrapper = styled.div`
  position: absolute;
  top: 50px;
  background: #222;
  padding: 15px;
  max-width: 400px;
`

const Values = styled.pre`
  padding: 15px;
  overflow: auto;
`

const StageDebug = () => {
  const mouse = useMousePosition()
  const stage = useSelector(store.selectStage)
  const dimensions = useSelector(store.selectDimensions)
  const selection = useSelector(store.selectSelection)

  const value = {
    stage,
    mouse,
    dimensions,
    selection,
  }

  return (
    <Wrapper data-testid={'StageDebug'}>
      Stage Debug

      <Values>
        {JSON.stringify(value, null, 2)}
      </Values>
    </Wrapper>
  )
}

export default StageDebug