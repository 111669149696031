import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import * as store from 'store'
import { useTemplateLoader } from 'hooks'
import Page from 'components/Page'
import Async, { Loading } from 'components/Async'
import { EditProjectForm } from 'components/ProjectForm'

type EditProjectPageProps = RouteComponentProps & {
  match: {
    params: {
      id: string
    }
  }
}

const EditProjectPage = ({ match }: EditProjectPageProps) => {
  const projectId = match.params.id
  const dispatch = store.useAppDispatch()
  const templateLoader = useTemplateLoader()

  useEffect(() => {
    dispatch(store.setSelectedProjectId(projectId))
  }, [])

  return (
    <Page>
      <FormattedMessage id={'EditProjectPage.title'} tagName={'h1'} />

      <Async fallback={<Loading />} isLoading={templateLoader.isLoading} error={templateLoader.error}>
        <EditProjectForm id={projectId} />
      </Async>
    </Page>
  )
}

export default EditProjectPage
