import { memo } from 'react'
import { useSelector } from 'react-redux'

import { ContextMenuOption } from 'store/types'
import * as store from 'store'
import { useContextMenu } from 'hooks'

const ProjectContextMenu = () => {
  const dispatch = store.useAppDispatch()
  const canUndo = useSelector(store.selectCanUndo)
  const canRedo = useSelector(store.selectCanRedo)

  const onClickUndo = () => dispatch(store.undo())
  const onClickRedo = () => dispatch(store.redo())
  const onClickSnapToGrid = () => dispatch(store.toggleGridSnapping())
  const onClickShowGrid = () => dispatch(store.toggleGridVisible())
  const onClickzoomIn = () => dispatch(store.zoomIn())
  const onClickZoomOut = () => dispatch(store.zoomOut())
  const onClickResetZoom = () => dispatch(store.resetZoom())

  const groups: Array<Array<ContextMenuOption>> = [
    [
      { id: 'Project.commands.undo', onClick: onClickUndo, disabled: !canUndo },
      { id: 'Project.commands.redo', onClick: onClickRedo, disabled: !canRedo },
    ],
    [
      { id: 'Project.commands.snapToGrid', onClick: onClickSnapToGrid },
      { id: 'Project.commands.toggleGrid', onClick: onClickShowGrid },
    ],
    [
      { id: 'Project.commands.zoomIn', onClick: onClickzoomIn },
      { id: 'Project.commands.zoomOut', onClick: onClickZoomOut },
      { id: 'Project.commands.resetZoom', onClick: onClickResetZoom },
    ]
  ]

  const menu = useContextMenu(groups)

  return <div data-testid={'ProjectContextMenu'}>{menu}</div>;
}

export default memo(ProjectContextMenu)