import styled from 'styled-components'
import * as Yup from 'yup'
import axios from 'axios'

import { IToast } from 'types'
import theme from 'theme'
import * as store from 'store'
import { useI18nFormatter } from 'hooks'
import { FormattedButton } from 'components/Button'
import Form, { TextField, SelectField } from 'components/Form'

type FormValues = {
  category: string
  message: string
}

const Title = styled.h2`
  font-weight: bold;
  display: flex;
  padding-bottom: ${theme.spacing.unit}px;
  margin: 0;
`

const Inputs = styled.div`
  flex: 1 200px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const categoryOptions = [
  { value: 'Bug', label: 'Bug' },
  { value: 'Suggestion', label: 'Suggestion' },
  { value: 'Other', label: 'Other' },
]

const schema = Yup.object().shape({
  category: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
})

const FeedbackModal = () => {
  const { fm } = useI18nFormatter()
  const dispatch = store.useAppDispatch()

  const addToast = (toast: Omit<IToast, 'id'>) => dispatch(store.addToast(toast))
  const hideModal = () => dispatch(store.hideModal())
  const resetLayout = () => dispatch(store.resetLayout())
  const onClickCancel = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    hideModal()
  }

  const onSubmit = async (values: FormValues) => {
    resetLayout()

    const data = {
      href: window.location.href,
      userAgent: window.navigator.userAgent,
      version: process.env.REACT_APP_VERSION,
      appState: JSON.stringify(store.store.getState()),
      ...values,
    }

    try {
      await axios.post('/api/feedback', data)
      addToast({ text: fm('Feedback.successMessage'), variant: 'success' })
    } catch (err) {
      addToast({ text: fm('Toasts.failedSubmit', { err: String(err) }), variant: 'error' })
    }
  }

  return (
    <Form
      enableReinitialize
      formName={'FeedbackModal'}
      initialValues={{ category: categoryOptions[0].value, message: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Title>{fm('Feedback.title')}</Title>

      <Inputs>
        <SelectField
          required
          name={'category'}
          label={fm('Feedback.category')}
          options={categoryOptions}
        />
        <TextField
          required
          name={'message'}
          label={fm('Feedback.message')}
          as={'textarea'}
          rows={5}
        />
      </Inputs>

      <Buttons>
        <FormattedButton
          data-testid={'AddCardModal-cancel'}
          id={'Buttons.cancel'}
          $variant={'secondary'}
          onPointerUp={onClickCancel}
        />
        <FormattedButton
          data-testid={'AddCardModal-add'}
          id={'Buttons.submit'}
          $variant={'primaryInverse'}
          type={'submit'}
        />
      </Buttons>
    </Form>
  )
}

export default FeedbackModal
