import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { AiOutlineDown } from 'react-icons/ai'

import theme from 'theme'
import * as store from 'store'
import { useI18n, useI18nFormatter } from 'hooks'
import { FormattedButton, LinkButton } from 'components/Button'
import Page from 'components/Page'

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
  min-height: calc(100vh - ${theme.header.height}px);
`

const FirstSectionContainer = styled(SectionContainer)`
  margin-top: -${theme.header.height}px;
`

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`

const SectionText = styled.div`
  flex: 1 ${theme.breakpoints.xs}px;
  margin: auto;
  padding: ${theme.spacing.unit}px;
  max-width: 100%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: ${theme.spacing.unit * 2}px;
    max-width: ${theme.breakpoints.sm}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: ${theme.spacing.unit * 3}px;
    max-width: ${theme.breakpoints.md}px;
  }
`

const SectionTextHeader = styled.span`
  color: ${theme.palette.secondary.light};
  width: 100%;
  font-size: ${props => props.theme.fontSize * 1.5}px;
  padding-right: ${theme.spacing.unit / 2}px;
  
  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: ${props => props.theme.fontSize * 3}px;
  }
`

const SectionTextMessage = styled.span`
  color: ${theme.palette.primary.xxxlight};
  font-size: ${props => props.theme.fontSize}px;
  line-height: ${props => props.theme.fontSize / 8};

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: ${props => props.theme.fontSize * 1.5}px;
    line-height:  ${props => props.theme.fontSize / 10}; 
  }
`

const TagLine = styled.h1`
  padding: ${theme.spacing.unit / 2}px;
  font-size: ${props => props.theme.fontSize * 2}px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: ${props => props.theme.fontSize * 4}px;
  }
`

const SubTagLine = styled.h2`
  padding: ${theme.spacing.unit / 2}px;
  color: ${theme.palette.primary.xxlight};
`

const Img = styled.img`
  flex: 1 ${theme.breakpoints.xs}px;
  border: 0px solid ${theme.palette.primary.inverse};
  width: 100%;
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;
`

const YoutubeEmbed = styled.div`
  flex: 1 ${theme.breakpoints.xs}px;
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;

  & > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 ratio */
    height: 0;
    overflow: hidden;

    & > iframe {
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%;
    }
  }
`

const YoutubeButton = styled.div`
  padding: ${theme.spacing.unit}px 0;
`

const Centered = styled.div`
  text-align: center;
`

const DownArrow = styled((props: any) => <AiOutlineDown size={50} role={'button'} {...props} />)`
  cursor: pointer;
  width: 100%;
  margin: ${theme.spacing.unit / 2}px auto;

  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

const HomePage = () => {
  const { locale } = useI18n()
  const { fm } = useI18nFormatter()
  const dispatch = store.useAppDispatch()
  const shouldLoadBaseCards = useSelector(store.selectShouldLoadBaseCards(locale))

  useEffect(() => {
    if (shouldLoadBaseCards) dispatch(store.loadBaseCards(locale))
  }, [])

  const onClickYoutubeLink = () => {
    window.open('https://www.youtube.com/playlist?list=PLDOiJR6CaHQGb2xe6y0SZTkURbBvg6xo5', '_blank')
  }

  const onClickDownArrow = (id: string) => () => {
    const section = document.getElementById(id)
    if (section) {
      const container = section.parentElement?.parentElement
      if (container) {
        container.scrollTo({ top: section.offsetTop - theme.header.height, behavior: 'smooth' })
      }
    }
  }

  return (
    <Page fluid>
      <FirstSectionContainer>
        <Section>
          <SectionText>
            <TagLine>{fm('HomePage.tagLine1')}</TagLine>
            <TagLine>{fm('HomePage.tagLine2')}</TagLine>
            <SubTagLine>{fm('HomePage.subTagLine')}</SubTagLine>
          </SectionText>
        </Section>
        <DownArrow onClick={onClickDownArrow('section1')} />
      </FirstSectionContainer>

      <SectionContainer id={'section1'}>
        <Section>
          <Img src={'images/example-template.png'} alt={'Example project'} />
          <SectionText>
            <SectionTextHeader>{fm('HomePage.section1.title')}</SectionTextHeader>
            <SectionTextMessage>{fm('HomePage.section1.message')}</SectionTextMessage>
          </SectionText>
        </Section>
        <DownArrow onClick={onClickDownArrow('section2')} />
      </SectionContainer>

      <SectionContainer id={'section2'}>
        <Section>
          <YoutubeEmbed>
            <div>
              <iframe
                allowFullScreen
                title={'Art of War Strategies intro video'}
                src={'https://www.youtube.com/embed/eISU-_1C7k4'}
                frameBorder={0}
                allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
              />
            </div>
          </YoutubeEmbed>
          <SectionText>
            <SectionTextHeader>{fm('HomePage.section2.title')}</SectionTextHeader>
            <SectionTextMessage>{fm('HomePage.section2.message')}</SectionTextMessage>
            <YoutubeButton>
              <FormattedButton id={'HomePage.youtubeLink'} $variant={'primaryInverse'} onClick={onClickYoutubeLink} />
            </YoutubeButton>
          </SectionText>
        </Section>
        <DownArrow onClick={onClickDownArrow('section3')} />
      </SectionContainer>

      <SectionContainer id={'section3'}>
        <Section>
          <SectionText>
            <Centered>
              <SectionTextHeader>{fm('HomePage.section3.title')}</SectionTextHeader>
              <SectionTextMessage>{fm('HomePage.section3.message1')}</SectionTextMessage>
              <LinkButton to={'/projects'} $variant={'secondary'}>{fm('ProjectDashboardPage.title')}</LinkButton>
              <Centered><SectionTextMessage>{fm('HomePage.section3.message2')}</SectionTextMessage></Centered>
              <LinkButton to={'/products'} $variant={'primaryInverse'}>{fm('ProductsPage.title')}</LinkButton>
            </Centered>
          </SectionText>
        </Section>
        <DownArrow onClick={onClickDownArrow('section4')} />
      </SectionContainer>

      <SectionContainer id={'section4'}>
        <Section>
          <Img src={'images/3QRC_REV003.png'} alt={'Donate using Venmo, CashApp or PayPal'} />
          <SectionText>
            <SectionTextHeader>{fm('HomePage.section4.title')}</SectionTextHeader>
            <SectionTextMessage>{fm('HomePage.section4.message1')}</SectionTextMessage>
          </SectionText>
        </Section>
      </SectionContainer>
    </Page>
  )
}

export default HomePage