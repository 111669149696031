import styled from 'styled-components'

import theme from 'theme'

type FieldProps = {
  name: string
  label: string
  error?: string
  touched: boolean
  children: React.ReactNode
}

const Wrapper = styled.div`
  padding-bottom: ${theme.spacing.unit / 3}px;
`

const Label = styled.label`
  display: block;
  font-weight: bold;
`

const FieldError = styled.div`
  color: ${theme.palette.secondary.main};
`

const Field = ({ name, label, error, touched, children }: FieldProps) => {
  return (
    <Wrapper data-testid={`Field-${name}`}>
      <Label htmlFor={name}>{label}</Label>
      {children}
      {error && touched && <FieldError>{error!}</FieldError>}
    </Wrapper>
  )
}

export default Field
