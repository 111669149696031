import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Route } from 'types'
import theme from 'theme'
import { useI18nFormatter } from 'hooks'
import { LanguageSelect } from 'components/I18n'
import { SettingsButton } from 'components/Settings'

export type MobileMenuProps = {
  routes: Route[]
  onDismiss: React.EventHandler<any>
}

const Wrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${theme.header.height - 1}px;
  display: 'flex';
  flex-direction: column;
  max-width: 100vw;
  z-index: ${theme.zIndex.header + 1};
  background: ${theme.palette.primary.inverse};
`

const MenuItemList = styled.ul`
  margin: 0;
  padding: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
`

const MenuItem = styled.li`
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.primary.xxlight};
  }
`

const NavLink = styled(Link) <{ $isActive?: boolean }>`
  flex: 1;
  color: ${({ $isActive }) => $isActive ? theme.palette.secondary.main : theme.palette.primary.main};
  text-decoration: none;
  padding: ${theme.spacing.unit}px;

  &:hover {
    color: ${theme.palette.secondary.light};
  }
`

const MenuButton = styled.div`
  padding: ${theme.spacing.unit / 2}px;
  width: 100%;
`

const MobileMenu = ({ routes, onDismiss }: MobileMenuProps) => {
  const { path } = useRouteMatch()
  const { fm } = useI18nFormatter()

  return (
    <Wrapper data-testid={'MobileMenu'}>
      <MenuItemList>
        {routes
          .filter((x) => x.headerLinkMobile)
          .map((route) => (
            <MenuItem key={route.key}>
              <NavLink to={route.path} $isActive={path === route.path}>
                {fm(`${route.key}.title`)}
              </NavLink>
            </MenuItem>
          ))}
      </MenuItemList>

      <MenuItemList>
        <MenuItem>
          <MenuButton>
            <LanguageSelect />
          </MenuButton>
        </MenuItem>
        <MenuItem>
          <MenuButton>
            <SettingsButton onClick={onDismiss} showLabel />
          </MenuButton>
        </MenuItem>
      </MenuItemList>
    </Wrapper>
  )
}

export default MobileMenu