import { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { MdSwapVert } from 'react-icons/md'

import { IArrow } from 'types'
import theme from 'theme'
import * as store from 'store'
import Button from 'components/Button'
import Form, { SelectField, TextField } from 'components/Form'

type ArrowPropertiesPanelProps = {
  id: string
}

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Title = styled.h2`
  border-bottom: 1px solid ${theme.palette.primary.xxlight};
  padding-bottom: ${theme.spacing.unit / 2}px;
`

const SwapButton = styled(MdSwapVert)`
  float: right;
  cursor: pointer;
`

const typeOptions = [
  { value: 'child', label: 'One Way' },
  { value: 'sibling', label: 'Two Way' },
]

const styleOptions = [
  { value: 'straight', label: 'Straight' },
  { value: 'angled', label: 'Angled' },
]

const weightOptions = [
  { value: 5, label: 'Extra Thin' },
  { value: 10, label: 'Thin' },
  { value: 15, label: 'Normal' },
  { value: 20, label: 'Thick' },
  { value: 25, label: 'Extra Thick' },
]

const ArrowPropertiesPanel = ({ id }: ArrowPropertiesPanelProps) => {
  const dispatch = store.useAppDispatch()
  const arrowMapped = useSelector(store.selectArrowMappedById(id))

  useEffect(() => {
    if (!arrowMapped) dispatch(store.hidePanel())
  }, [arrowMapped])

  if (!arrowMapped) return null

  const { to, from, type, weight, style } = arrowMapped

  const values = {
    from: from.id,
    to: to.id,
    type,
    weight,
    style,
  }

  const updateArrow = (changes: Partial<IArrow>, commitToHistory: boolean) => dispatch(store.updateArrow({ updates: { id, changes }, commitToHistory }))
  const deleteArrow = () => dispatch(store.deleteArrow(id))
  const swapArrowDirection = () => updateArrow({ from: to.id, to: from.id }, true)

  const maybeUpdateArrow = (commitToHistory: boolean) => (event: React.FocusEvent<any>) => {
    const { name, value } = event.target

    if (values[name as keyof typeof values] !== value || commitToHistory) {
      updateArrow({ [name]: value }, commitToHistory)
    }
  }

  const onChange = maybeUpdateArrow(false)
  const onBlur = maybeUpdateArrow(true)

  return (
    <Wrapper
      formName={'ArrowPropertiesPanel'}
      enableReinitialize
      initialValues={values}
      onSubmit={() => { }}
    >
      <div>
        <Title>Relationship</Title>

        <TextField
          disabled
          name={'from'}
          label={'From'}
          value={from.name || from.title}
        />
        <SwapButton
          data-testid={'ArrowPropertiesPanel-swap-direction'}
          aria-label={'Swap Direction'}
          onPointerDown={swapArrowDirection}
        />
        <TextField
          disabled
          name={'to'}
          label={'To'}
          value={to.name || to.title}
        />
        <SelectField
          name={'type'}
          label={'Type'}
          options={typeOptions}
          onChange={onChange}
        />
        <SelectField
          name={'style'}
          label={'Style'}
          options={styleOptions}
          onChange={onChange}
          onBlur={onBlur}
        />
        <SelectField
          name={'weight'}
          label={'Weight'}
          options={weightOptions}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>

      <Button
        data-testid={'ArrowPropertiesPanel-delete-arrow'}
        type={'button'}
        $variant={'secondary'}
        onClick={deleteArrow}
      >
        <FormattedMessage id={'Buttons.delete'} />
      </Button>
    </Wrapper>
  )
}

export default ArrowPropertiesPanel
