import { Formik, Form as FormikForm, FormikConfig, FormikHelpers } from 'formik'

type FormProps = FormikConfig<any> & {
  formName: string
  className?: string
  children: React.ReactNode
}

const Form = ({ children, formName, initialValues, onSubmit, className, ...props }: FormProps) => {
  const handleSubmit = (values: { [k: string]: string }, formikHelpers: FormikHelpers<any>) => {
    Object.keys(initialValues).forEach((k) => {
      const input = document.querySelector(`#${formName} [name=${k}]`) as any
      input && input.blur()
    })
    onSubmit(values, formikHelpers)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      {...props}
    >
      <FormikForm data-testid={formName} id={formName} className={className} noValidate>
        {children}
      </FormikForm>
    </Formik>
  )
}

export default Form
