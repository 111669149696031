import { FormattedMessage } from 'react-intl'

import Button, { ButtonProps } from './Button'

export type FormattedButtonProps = ButtonProps & {
  id: string
}

const FormattedButton = ({ id, ...rest }: FormattedButtonProps) => {
  return (
    <Button {...rest}>
      <FormattedMessage id={id} />
    </Button>
  )
}

export default FormattedButton