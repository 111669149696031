import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { IToast } from 'types'
import theme from 'theme'
import * as store from 'store'
import { useI18nFormatter } from 'hooks'
import { FormattedButton } from 'components/Button'
import Form, { SelectField, TextField } from 'components/Form'

type EditProjectFormProps = {
  id: string
}

type FormValues = {
  name: string
}

const Fields = styled.div`
  padding: ${theme.spacing.unit / 2}px 0;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const EditProjectForm = ({ id }: EditProjectFormProps) => {
  const { fm } = useI18nFormatter()
  const history = useHistory()
  const dispatch = store.useAppDispatch()
  const templateOptions = useSelector(store.selectTemplateOptions)
  const projectSummary = useSelector(store.selectProjectSummary)

  const addToast = (toast: Omit<IToast, 'id'>) => dispatch(store.addToast(toast))
  const editProject = (changes: FormValues) => dispatch(store.editProject({ changes, id }))
  const onCancel = () => history.push('/projects')

  const onSubmit = async (changes: FormValues) => {
    const res: any = await editProject(changes)

    if (res.error) {
      addToast({ text: fm('Toasts.failedSave', { err: String(res.error) }), variant: 'error' })
    } else {
      addToast({ text: fm('Toasts.successSave'), variant: 'success' })
      history.push('/projects')
    }
  }

  return (
    <Form
      enableReinitialize
      formName={'EditProjectForm'}
      initialValues={projectSummary}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Fields>
        <TextField
          required
          name={'name'}
          label={'Name'}
          placeholder={'Enter a name for the project'}
        />
        <SelectField
          disabled
          name={'template'}
          label={'Template'}
          options={templateOptions}
        />
      </Fields>

      <Buttons>
        <FormattedButton
          data-testid={'EditProjectForm-cancel'}
          id={'Buttons.cancel'}
          $variant={'secondary'}
          onPointerUp={onCancel}
        />
        <FormattedButton
          data-testid={'EditProjectForm-save'}
          id={'Buttons.save'}
          $variant={'primaryInverse'}
          type={'submit'}
        />
      </Buttons>
    </Form>
  )
}

export default EditProjectForm