import { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { ICard } from 'types'
import theme from 'theme'
import * as store from 'store'
import { getCardQuote } from 'utils/cardUtils'
import { FormattedButton } from 'components/Button'
import Form, { TextField } from 'components/Form'

import CardPropertiesPanelDebugger from './CardPropertiesPanelDebugger'

type CardPropertiesPanelProps = {
  id: string
}

type FormValues = {
  name: string
  notes: string
}

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

const Title = styled.h2`
  border-bottom: 1px solid ${theme.palette.primary.xxlight};
  padding-bottom: ${theme.spacing.unit / 2}px;
`

const Quote = styled.div`
  margin: ${theme.spacing.unit}px 0;
  font-style: italic;
`

const Description = styled.div`
  margin: ${theme.spacing.unit}px 0;
  font-size: 18px;
  font-weight: lighter;
`

const CardPropertiesPanel = ({ id }: CardPropertiesPanelProps) => {
  const dispatch = store.useAppDispatch()
  const { showDebugInfo } = useSelector(store.selectSettings)
  const cardMapped = useSelector(store.selectCardMappedById(id))

  useEffect(() => {
    if (!cardMapped) dispatch(store.hidePanel())
  }, [cardMapped])

  if (!cardMapped) return null

  const {
    title,
    description,
    quoteId,
    quotes,
    placeholders,
  } = cardMapped

  const maxLength = Math.max(placeholders.name.length, placeholders.notes.length)
  const placeholderIndex = Math.floor(Math.random() * maxLength)
  const quote = getCardQuote(quotes, quoteId)

  const updateCard = (changes: Partial<ICard>, commitToHistory: boolean) => dispatch(store.updateCard({ updates: { id, changes }, commitToHistory }))
  const deleteCard = () => dispatch(store.deleteCard(id))

  const maybeUpdateCard = (commitToHistory: boolean) => (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target

    if (cardMapped[name as keyof typeof cardMapped] !== value || commitToHistory) {
      updateCard({ [name]: value }, commitToHistory)
    }
  }

  const onChange = maybeUpdateCard(false)
  const onBlur = maybeUpdateCard(true)
  const onSubmit = (values: FormValues) => { }

  return (
    <Wrapper
      enableReinitialize
      formName={'CardPropertiesPanel'}
      initialValues={cardMapped}
      onSubmit={onSubmit}
    >
      <div>
        <Title>{title}</Title>
        {quote && <Quote>{quote}</Quote>}
        <Description>{description}</Description>

        <TextField
          name={'name'}
          label={'Name / Description'}
          as={'textarea'}
          rows={3}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholders.name[placeholderIndex % placeholders.name.length]}
        />
        <TextField
          name={'notes'}
          label={'Notes'}
          as={'textarea'}
          rows={10}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholders.notes[placeholderIndex % placeholders.notes.length]}
        />
      </div>

      {showDebugInfo && <CardPropertiesPanelDebugger id={id} onChange={onChange} />}

      <FormattedButton
        data-testid={'CardPropertiesPanel-delete-card'}
        $variant={'secondary'}
        id={'Buttons.delete'}
        type={'button'}
        onClick={deleteCard}
      />
    </Wrapper>
  )
}

export default CardPropertiesPanel