export const keys = {
  escape: 'Escape',
  enter: 'Enter',
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
}

export const preventEvent = (event: React.SyntheticEvent) => {
  event.preventDefault()
  event.stopPropagation()
}