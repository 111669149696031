import styled from 'styled-components'

import theme from 'theme'
import Header from 'components/Header'
import Footer from 'components/Footer'

import SideActions from './SideActions'

type PageProps = {
  children: React.ReactNode
  fluid?: boolean
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  margin-top: ${theme.header.height}px;
  height: 100%;
  overflow: auto;
`

const Content = styled.div<{ $fluid?: boolean }>`
  margin: auto;
  min-height: 100%;
  width: 100%;
  padding: ${theme.spacing.unit / 3}px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: ${({ $fluid }) => $fluid ? '100%' : `${theme.breakpoints.sm - (theme.spacing.unit / 2)}px`};
    padding: ${theme.spacing.unit / 2}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    width: ${({ $fluid }) => $fluid ? '100%' : `${theme.breakpoints.md - (theme.spacing.unit / 2)}px`};
    padding: ${theme.spacing.unit}px;
  }
`

const Page = ({ children, fluid }: PageProps) => {
  return (
    <Wrapper data-testid={'Page'}>
      <Header />
      <Main>
        <Content $fluid={fluid}>{children}</Content>
        <Footer />
      </Main>
      <SideActions />
    </Wrapper>
  )
}

export default Page
