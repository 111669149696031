import { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import { FlooredNumber, Rect, Dimensions } from 'types'
import { StageState } from 'store/types'
import * as store from 'store'
import { getCardsWithinSelection, getPointFromEvent, buttons, isSameRect } from 'utils'
import { ArrowLayer, ArrowDefs } from 'components/Arrow'
import { CardLayer } from 'components/Card'
import Grid, { GridDefs } from 'components/Grid'
import Selection, { SelectedBoundingBox } from 'components/Selection'

import StageDebug from './StageDebug'

const PAN_SPEED = 20

const SVG = styled.svg`
  cursor: move;
`

const Stage = () => {
  const stageRef = useRef(null)
  const dispatch = store.useAppDispatch()
  const { showDebugInfo } = useSelector(store.selectSettings)
  const { x, y, zoom } = useSelector(store.selectStage)
  const dimensions = useSelector(store.selectDimensions)
  const isSelecting = useSelector(store.selectIsSelecting)
  const selectionRect = useSelector(store.selectSelectionRect)
  const totalSelected = useSelector(store.selectTotalSelected)
  const cards = useSelector(store.selectAllCards)

  const [viewBox, setViewBox] = useState<{ x: FlooredNumber, y: FlooredNumber }>({ x, y })
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [isActive, setIsActive] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [activeButton, setActiveButton] = useState(-1)

  const multiSelect = totalSelected > 1

  const showContextMenu = (props: any) => dispatch(store.showContextMenu(props))
  const resetLayout = () => dispatch(store.resetLayout())
  const setSelectionRect = (selection: Rect) => dispatch(store.setSelectionRect(selection))
  const clearSelectionRect = () => dispatch(store.clearSelectionRect())
  const setSelected = (ids: string[]) => dispatch(store.setSelected(ids))
  const updateStage = (updates: Partial<StageState>) => dispatch(store.updateStage(updates))
  const setDimensions = (value: Dimensions) => dispatch(store.setDimensions(value))

  const updateCoords = (coords: { x: FlooredNumber, y: FlooredNumber }) => {
    updateStage(coords)
    setViewBox(coords)
  }

  // UNMOUNT

  useEffect(() => {
    return () => {
      resetLayout()
      setSelected([])
    }
  }, [])

  // RESIZE EVENTS

  const handleResize = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    const handleResizeDebounced = debounce(handleResize, 100)
    window.addEventListener('resize', handleResizeDebounced)
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [])

  // KEY EVENTS

  const handleKeydown = useCallback((event: KeyboardEvent) => {
    if ((event.target as any).tagName !== 'BODY') return
    const key = event.key.toLowerCase()
    // HISTORY
    if (event.ctrlKey && key === 'z') {
      if (event.shiftKey) {
        dispatch(store.redo())
      } else {
        dispatch(store.undo())
      }
    }
    // DELETE
    else if (key === 'delete') {
      dispatch(store.deleteSelected())
      dispatch(store.setSelected([]))
    }
    // PAN
    else if (key === 'w' || key === 'arrowup') {
      updateCoords({ x, y: Math.floor(y - (PAN_SPEED / zoom)) as FlooredNumber })
    } else if (key === 'a' || key === 'arrowleft') {
      updateCoords({ x: Math.floor(x - (PAN_SPEED / zoom)) as FlooredNumber, y })
    } else if (key === 's' || key === 'arrowdown') {
      updateCoords({ x, y: Math.floor(y + (PAN_SPEED / zoom)) as FlooredNumber })
    } else if (key === 'd' || key === 'arrowright') {
      updateCoords({ x: Math.floor(x + (PAN_SPEED / zoom)) as FlooredNumber, y })
    }
  }, [stageRef, x, y, zoom])

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown)
    return () => window.removeEventListener('keydown', handleKeydown)
  }, [handleKeydown])

  // MOUSE EVENTS

  const onPointerDown = (event: React.PointerEvent<SVGSVGElement>) => {
    if (event.target !== stageRef.current) return;
    ; (event.target as SVGGElement).setPointerCapture(event.pointerId)
    const pointerPosition = getPointFromEvent(event)

    setActiveButton(event.button)
    setIsActive(true)
    setOffset(pointerPosition)
    setViewBox({ x, y })
  }

  const onPointerUp = (event: React.PointerEvent<SVGSVGElement>) => {
    if (!isActive || event.target !== stageRef.current) return
    event.stopPropagation()

    if (activeButton === buttons.left) {
      if (isSelecting) {
        const selectedCardIds = getCardsWithinSelection(cards, selectionRect).map(x => x.id)
        setSelected(selectedCardIds)
        clearSelectionRect()

        if (selectedCardIds.length > 1) {
          resetLayout()
        }
      } else {
        resetLayout()
        setSelected([])
      }

      if (isMoving) {
        setIsMoving(false)
        setViewBox({ x, y })
      }
    }

    setIsActive(false)
    setActiveButton(-1)
  }

  const onPointerMove = (event: React.PointerEvent<SVGSVGElement>) => {
    if (!isActive) return

    const pointerPosition = getPointFromEvent(event)
    const offsetPointer = {
      x: Math.floor((pointerPosition.x - offset.x) / zoom) as FlooredNumber,
      y: Math.floor((pointerPosition.y - offset.y) / zoom) as FlooredNumber,
    }

    if (activeButton === buttons.left) {
      if (event.ctrlKey || event.shiftKey) {
        const rtl = offsetPointer.x < 0
        const btt = offsetPointer.y < 0

        const newSelection: Rect = {
          x: Math.floor(x + (rtl ? pointerPosition.x : offset.x) / zoom),
          y: Math.floor(y + (btt ? pointerPosition.y : offset.y) / zoom),
          width: rtl ? (offset.x - pointerPosition.x) / zoom : offsetPointer.x,
          height: btt ? (offset.y - pointerPosition.y) / zoom : offsetPointer.y,
        }

        if (!isSameRect(selectionRect, newSelection)) {
          setSelectionRect(newSelection)
        }
      } else {
        const newX = (viewBox.x - offsetPointer.x) as FlooredNumber
        const newY = (viewBox.y - offsetPointer.y) as FlooredNumber

        if (newX !== x || newY !== y) {
          event.preventDefault()
          updateStage({ x: newX, y: newY })

          if (!isMoving) {
            setIsMoving(true)
          }
        }
      }
    }
  }

  const onContextMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault()
    if (event.target !== stageRef.current) return
    showContextMenu({ name: 'ProjectContextMenu', props: { x: event.clientX, y: event.clientY } })
  }

  return (
    <>
      {showDebugInfo && <StageDebug />}

      <SVG
        data-testid={'Stage'}
        width={'100%'}
        height={'100%'}
        viewBox={`${x} ${y} ${dimensions.width / zoom} ${dimensions.height / zoom}`}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerLeave={onPointerUp}
        onPointerMove={onPointerMove}
        onContextMenu={onContextMenu}
        ref={stageRef}
      >
        <GridDefs />
        <Grid />
        <ArrowDefs />
        <ArrowLayer />
        <CardLayer />
        {multiSelect && <SelectedBoundingBox />}
        <Selection />
      </SVG>
    </>
  )
}

export default Stage