import { FlooredNumber, ICard } from 'types'
import theme from 'theme'

export const getOffsetPosition = (
  event: React.PointerEvent<SVGGElement>,
  position: { x: number; y: number },
  offset: { x: number; y: number },
  zoom: number
) => {
  const { target, clientX, clientY } = event

  const bbox = (target as SVGGElement).getBoundingClientRect()
  const xOffset = Math.floor((clientX - bbox.left) / zoom)
  const yOffset = Math.floor((clientY - bbox.top) / zoom)
  const newX = (position.x - (offset.x - xOffset)) as FlooredNumber
  const newY = (position.y - (offset.y - yOffset)) as FlooredNumber

  return { newX, newY, xOffset, yOffset }
}

export const getCardAtPosition = (cards: ICard[], x: number, y: number) => {
  return cards.find(
    (card) =>
      card.x < x && card.y < y && card.x + theme.card.width > x && card.y + theme.card.height > y
  )
}