import { useIntl } from 'react-intl'

const useI18nFormatter = () => {
  const intl = useIntl()

  return {
    fm: (id: string, values?: Record<string, any>) => intl.formatMessage({ id }, values)
  }
}

export default useI18nFormatter