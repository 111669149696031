import { useState, useEffect } from 'react'

import { getPointFromEvent } from 'utils'

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const updateMousePosition = (event: MouseEvent) => {
    setMousePosition(getPointFromEvent(event))
  }

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition)
    return () => window.removeEventListener('mousemove', updateMousePosition)
  }, [])

  return mousePosition
}

export default useMousePosition