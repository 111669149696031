import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { useTemplateLoader } from 'hooks'
import Async from 'components/Async'
import Page from 'components/Page'
import { NewProjectButton } from 'components/Project'
import ProjectList, { ProjectListLoading } from 'components/ProjectList'

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.unit / 2}px;
`

const ProjectDashboardPage = () => {
  const templateLoader = useTemplateLoader()
  const projectListIsLoading = useSelector(store.selectProjectListIsLoading)
  const projectListError = useSelector(store.selectProjectListError)

  const isLoading = templateLoader.isLoading || projectListIsLoading
  const error = templateLoader.error || projectListError

  return (
    <Page>
      <Heading>
        <FormattedMessage id={'ProjectDashboardPage.title'} tagName={'h1'} />
        <NewProjectButton />
      </Heading>
      <FormattedMessage id={'ProjectDashboardPage.disclaimer'} tagName={'p'} />

      <Async fallback={<ProjectListLoading />} isLoading={isLoading} error={error}>
        <ProjectList />
      </Async>
    </Page>
  )
}

export default ProjectDashboardPage
