import { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FlooredNumber, ICard } from 'types'
import theme from 'theme'
import * as store from 'store'
import { buttons } from 'utils/mouse'
import { getCardQuote } from 'utils/cardUtils'
import { getOffsetPosition } from 'utils/scene'
import { Text } from 'components/Element'

import CardQuickActions from './CardQuickActions'

type CardProps = {
  id: string
}

const {
  width,
  height,
  borderWidth,
  padding,
  titleHeight,
  quoteHeight,
  imgHeight,
} = theme.card
const { primary, secondary } = theme.palette
const { unit } = theme.grid

const Group = styled.g<{ multiSelect: boolean }>`
  cursor: ${({ multiSelect }) =>
    multiSelect ? 'move' : 'pointer'};
  user-select: none;
`

const Rect = styled.rect<{ isSelected: boolean }>`
  fill: ${primary.dark};
  stroke: ${({ isSelected }) =>
    isSelected ? secondary.main : secondary.inverse};
  stroke-width: ${borderWidth};
`

const Card = ({ id }: CardProps) => {
  const dispatch = store.useAppDispatch()
  const fontSize = Number(useSelector(store.selectFontSize))
  const isGridSnapping = useSelector(store.selectGridIsSnapping)
  const zoom = useSelector(store.selectZoom)
  const cardMapped = useSelector(store.selectCardMappedById(id))
  const isSelected = useSelector(store.selectIsSelected(id))
  const totalSelected = useSelector(store.selectTotalSelected)

  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [isActive, setIsActive] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [isMousedOver, setMousedOver] = useState(false)

  if (!cardMapped) return null

  const { x, y, title, name, quoteId, quotes, imageSrc } = cardMapped
  const contentWidth = width - borderWidth * 2 - padding * 2
  const multiSelect = totalSelected > 1
  const quote = getCardQuote(quotes, quoteId)

  const showContextMenu = (props: any) => dispatch(store.showContextMenu(props))
  const showPanel = (props: any) => dispatch(store.showPanel(props))
  const hidePanel = () => dispatch(store.hidePanel())
  const updateCard = (changes: Partial<ICard>, commitToHistory?: boolean) => dispatch(store.updateCard({ updates: { id, changes }, commitToHistory }))
  const addSelected = () => dispatch(store.addSelected(id))
  const setSelected = (ids: string[]) => dispatch(store.setSelected(ids))
  const removeSelected = () => dispatch(store.removeSelected(id))

  const toggleSelection = (event: React.MouseEvent<any>) => {
    if (event.button !== 0) return

    let showPropertiesPanel = false
    if (isSelected) {
      if (event.shiftKey || event.ctrlKey) {
        showPropertiesPanel = totalSelected === 2
        removeSelected()
      } else {
        setSelected([])
      }
    } else {
      if (event.shiftKey || event.ctrlKey) {
        showPropertiesPanel = !totalSelected
        addSelected()
      } else {
        showPropertiesPanel = true
        setSelected([id])
      }
    }

    if (showPropertiesPanel) {
      showPanel({ name: 'CardPropertiesPanel', props: { id } })
    } else {
      hidePanel()
    }
  }

  const commitNewPosition = () => {
    updateCard({
      x: isGridSnapping ? Math.round(x / unit) * unit as FlooredNumber : x,
      y: isGridSnapping ? Math.round(y / unit) * unit as FlooredNumber : y,
    }, true)
  }

  const onPointerDown = (event: React.PointerEvent<SVGGElement>) => {
    if (event.button !== buttons.left) return

    const bbox = (event.target as SVGGElement).getBoundingClientRect();
    (event.target as SVGGElement).setPointerCapture(event.pointerId)
    setIsActive(true)
    setOffset({ x: Math.floor((event.clientX - bbox.left) / zoom), y: Math.floor((event.clientY - bbox.top) / zoom) })
  }

  const onPointerUp = (event: React.PointerEvent<SVGGElement>) => {
    event.stopPropagation()
    if (event.button !== buttons.left) return

    if (!isMoving) {
      toggleSelection(event)
    } else {
      commitNewPosition()
    }

    setIsMoving(false)
    setIsActive(false)
  }

  const onPointerMove = (event: React.PointerEvent<SVGGElement>) => {
    const { newX, newY } = getOffsetPosition(event, { x, y }, offset, zoom)

    if (isActive && (newX !== x || newY !== y)) {
      setIsMoving(true)
      updateCard({ x: newX, y: newY })
    }
  }

  const onContextMenu = (event: React.PointerEvent<SVGGElement>) => {
    event.preventDefault()
    showContextMenu({ name: 'CardContextMenu', props: { x: event.clientX, y: event.clientY, id } })
  }

  const onPointerEnter = (event: any) => {
    setMousedOver(true)
  }

  const onPointerLeave = (event: any) => {
    setMousedOver(false)
  }

  return (
    <g
      transform={`translate(${x},${y})`}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <CardQuickActions id={id} show={isMousedOver && !multiSelect} />

      <Group
        data-testid={`Card-${id}`}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerMove={onPointerMove}
        onContextMenu={onContextMenu}
        multiSelect={multiSelect}
      >
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          isSelected={isSelected}
        />

        {title && <Text
          x={Math.floor(borderWidth + padding + contentWidth / 2)}
          y={borderWidth + padding}
          width={contentWidth}
          height={titleHeight}
          textAnchor={'middle'}
          verticalAlign={'middle'}
          fontSize={fontSize}
          fontWeight={'bold'}
          fill={theme.palette.primary.inverse}
        >
          {title}
        </Text>}

        {name ? (
          <>
            <rect
              x={Math.floor(borderWidth / 2)}
              y={Math.floor(borderWidth + padding + titleHeight)}
              width={width - borderWidth}
              height={Math.floor(quoteHeight + padding * 2)}
              fill={theme.palette.primary.inverse}
            />
            <Text
              x={borderWidth + padding}
              y={Math.floor(borderWidth + padding + titleHeight + padding)}
              width={contentWidth}
              height={quoteHeight}
              fontSize={Math.floor(fontSize * 0.8)}
              fill={theme.palette.primary.dark}
            >
              {name}
            </Text>
          </>
        ) : (
            quote && (
              <Text
                x={borderWidth + padding}
                y={Math.floor(borderWidth + padding + titleHeight + padding)}
                width={contentWidth}
                height={quoteHeight}
                fontSize={Math.floor(fontSize * 0.8)}
                fontStyle={'italic'}
                fill={theme.palette.primary.inverse}
              >
                {quote}
              </Text>
            )
          )}

        <image
          x={borderWidth + padding}
          y={
            Math.floor(
              borderWidth +
              padding +
              titleHeight +
              padding * 1.5 +
              quoteHeight +
              padding
            )
          }
          width={contentWidth}
          height={imgHeight}
          xlinkHref={imageSrc}
          preserveAspectRatio={'none'}
        />
      </Group>
    </g>
  )
}

export default memo(Card)
