import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RiSettings2Line } from 'react-icons/ri'

import theme from 'theme'
import * as store from 'store'
import { useI18nFormatter } from 'hooks'
import { keys, preventEvent } from 'utils/event'

type SettingsButtonProps = {
  showIcon?: boolean
  showLabel?: boolean
  onClick?: (event: React.SyntheticEvent) => void
}

const Wrapper = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.unit / 2}px;
  color: ${props => props.$active ? theme.palette.secondary.main : theme.palette.primary.main};
  width: 100%;

  &:hover {
    color: ${theme.palette.secondary.light};
  }
`

const SettingsLabelSpacer = styled.div`
  padding: ${theme.spacing.unit / 2}px;
`

const SettingsLabel = styled.div`
  flex: 1;
`

const SettingsButton = ({ showIcon, showLabel, onClick }: SettingsButtonProps) => {
  const dispatch = useDispatch()
  const { fm } = useI18nFormatter()
  const panel = useSelector(store.selectPanel)

  const showPanel = (props: any) => dispatch(store.showPanel(props))
  const showSettings = () => showPanel({ name: 'SettingsPanel', props: { id: 'SettingsPanel' } })

  const onClickSettings = (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (onClick) onClick(event)
    showSettings()
  }

  const onKeyDownSettings = (event: React.KeyboardEvent<any>) => {
    if (event.key !== keys.enter) return
    if (onClick) onClick(event)
    showSettings()
  }

  return (
    <Wrapper
      data-testid={'Settings'}
      aria-label={'Settings'}
      role={'button'}
      tabIndex={0}
      onPointerDown={preventEvent}
      onPointerUp={onClickSettings}
      onKeyDown={onKeyDownSettings}
      $active={panel.name === 'SettingsPanel'}
    >
      {showIcon && <RiSettings2Line />}
      {showIcon && showLabel && <SettingsLabelSpacer />}
      {showLabel && <SettingsLabel>{fm('Settings.title')}</SettingsLabel>}
    </Wrapper>
  )
}

export default SettingsButton
