import { memo } from 'react'
import { useSelector } from 'react-redux'

import * as store from 'store'

import Card from './Card'
import TargetCard from './TargetCard'

const CardLayer = () => {
  const ids = useSelector(store.selectCardIds)
  const sourceId = useSelector(store.selectSourceId)
  const targetId = useSelector(store.selectTargetId)

  return (
    <>
      {ids.map(id => <Card key={id} id={id as string} />)}
      {sourceId && targetId && <TargetCard />}
    </>
  )
}

export default memo(CardLayer)