import { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

type AsyncProps = RouteComponentProps & {
  isLoading: boolean
  error?: string | null | undefined
  fallback: React.ReactNode
}

type AsyncState = {
  error: string
}

class Async extends Component<AsyncProps, AsyncState> {
  constructor(props: AsyncProps) {
    super(props);
    this.state = { error: '' };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('caught: ', error, errorInfo)
  }

  render() {
    const { children, fallback, history, isLoading } = this.props

    const error = this.state.error || this.props.error

    if (error) {
      history.push('/error', { error })
      return null
    }

    if (isLoading) return fallback

    return children;
  }
}

export default withRouter(Async)