import { ArrowWeights } from 'types'
import theme from 'theme'

const weights = [5, 10, 15, 20, 25] as ArrowWeights[]
const types = ['start', 'end']
const modifiers = ['', '--selected']

export const ArrowDefs = () => (
  <defs>
    {weights.map((weight) => {
      const strokeWidth = 2
      const refX = strokeWidth + 1
      const refY = weight + (weight / 2) + strokeWidth
      const markerWidth = (weight * 2) + (strokeWidth * 2)
      const markerHeight = (weight * 3) + (strokeWidth * 2)
      const d = `M ${strokeWidth} ${weight + strokeWidth} v -${weight} l ${weight * 2}, ${weight + (weight / 2)} -${weight * 2}, ${weight + (weight / 2)} v -${weight}`

      return types.map((type) => (
        modifiers.map((modifier) => {
          const id = `arrow-${weight}-${type}${modifier}`
          const fill = modifier === '--selected' ? theme.palette.secondary.main : theme.palette.secondary.inverse

          return (
            <marker key={id} id={id} markerWidth={markerWidth} markerHeight={markerHeight} refX={refX} refY={refY} orient="auto-start-reverse" markerUnits="userSpaceOnUse">
              <path fill={fill} stroke={theme.palette.primary.dark} strokeWidth={strokeWidth} d={d} />
            </marker>
          )
        })
      ))
    })}
  </defs>
)

export default ArrowDefs