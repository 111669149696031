import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { IArrow } from 'types'
import theme from 'theme'
import * as store from 'store'

const BoundingBox = styled.rect`
  fill: ${theme.palette.secondary.main};
  cursor: none;
  opacity: 0.2;
`

const TargetCard = () => {
  const dispatch = store.useAppDispatch()
  const sourceId = useSelector(store.selectSourceId)
  const targetId = useSelector(store.selectTargetId)!
  const card = useSelector((state: store.RootState) => store.selectCardById(state, targetId))!

  const addArrow = (values: Partial<IArrow>) => dispatch(store.addArrow(values))
  const clearSourceId = () => dispatch(store.setSourceId(null))
  const clearTargetId = () => dispatch(store.setTargetId(null))

  const onClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    if (sourceId) {
      addArrow({ from: sourceId, to: targetId })
      clearSourceId()
      clearTargetId()
    }
  }

  return (
    <BoundingBox
      data-testid={'TargetCard'}
      x={card.x}
      y={card.y}
      width={theme.card.width}
      height={theme.card.height}
      onPointerUp={onClick}
    />
  )
}

export default memo(TargetCard)
