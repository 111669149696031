import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { TextField } from 'components/Form'

type CardPropertiesPanelDebuggerProps = {
  id: string
  onChange: any
}

const Values = styled.pre`
  max-width: 100%;
  overflow: auto;
  background: ${theme.palette.primary.dark};
`

const CardPropertiesPanelDebugger = ({ id, onChange }: CardPropertiesPanelDebuggerProps) => {
  const cardMapped = useSelector(store.selectCardMappedById(id))

  return (
    <div>
      <h2>Card Debug</h2>

      <TextField
        name={'z'}
        label={'Z index'}
        onChange={onChange}
      />

      <Values>{JSON.stringify(cardMapped, null, 2)}</Values>
    </div>
  )
}

export default CardPropertiesPanelDebugger
