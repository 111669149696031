import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as store from 'store'
import { useBaseCardLoader } from 'hooks'
import Async, { Loading } from 'components/Async'
import CommandBar from 'components/CommandBar'
import CommandPanel from 'components/CommandPanel'
import Stage, { StageFooter } from 'components/Stage'

type ProjectPageProps = RouteComponentProps & {
  match: {
    params: {
      id: string
    }
  }
}

const ProjectPage = ({ match }: ProjectPageProps) => {
  const projectId = match.params.id
  const dispatch = store.useAppDispatch()
  const baseCardLoader = useBaseCardLoader()
  const selectedProjectId = useSelector(store.selectSelectedProjectId)
  
  useEffect(() => {
    if (selectedProjectId !== projectId) dispatch(store.setSelectedProjectId(projectId))
  }, [])

  return (
    <Async fallback={<Loading />} isLoading={baseCardLoader.isLoading} error={baseCardLoader.error}>
      <CommandBar />
      <CommandPanel />
      <Stage />
      <StageFooter />
    </Async>
  )
}

export default ProjectPage
