import styled from 'styled-components'

import theme from 'theme'
import { preventEvent } from 'utils'

type CommandButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string
  children: React.ReactNode
  isActive?: boolean
}

const Button = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  outline: none;
  height: ${theme.commandBar.height}px;
  padding: 0 ${theme.spacing.unit / 2}px;
  color: ${({ isActive }) => isActive ? theme.palette.secondary.main : theme.palette.primary.main};

  &:enabled:hover {
    color: ${theme.palette.secondary.light};
    cursor: pointer;
  }

  &:disabled {
    color: ${theme.palette.disabled.main};
  }
`

const CommandButton = ({ title, children, ...props }: CommandButtonProps) => {
  return <Button aria-label={title} title={title} onPointerDown={preventEvent} {...props}>{children}</Button>
}

export default CommandButton
