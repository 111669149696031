import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { Panels } from 'store/types'
import { useClickOutside } from 'hooks'
import { ArrowPropertiesPanel } from 'components/Arrow'
import { CardPropertiesPanel } from 'components/Card'
import { SettingsPanel } from 'components/Settings'

const Wrapper = styled.div`
  position: absolute;
  background: ${theme.palette.primary.main};
  border-left: 1px solid ${theme.palette.primary.light};
  height: calc(100% - ${theme.commandBar.height}px);
  width: 20vw;
  min-width: 350px;
  right: 0;
  bottom: 0;
  padding: ${theme.spacing.unit}px;
  z-index: ${theme.zIndex.commandBar - 1};
  overflow: auto;
`

const panels: { [k in Panels]: React.FC<any> } = {
  CardPropertiesPanel,
  ArrowPropertiesPanel,
  SettingsPanel,
}

const Panel = () => {
  const dispatch = store.useAppDispatch()
  const onDismiss = () => dispatch(store.hidePanel())
  const { name, props } = useSelector(store.selectPanel)
  const { ref } = useClickOutside(onDismiss)
  const C = name ? panels[name] : null

  if (!C || !props) return null

  return <Wrapper data-testid={'Panel'} ref={ref}><C {...props} /></Wrapper>
}

export default memo(Panel)
