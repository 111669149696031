import { memo } from 'react'
import { useSelector } from 'react-redux'

import theme from 'theme'
import * as store from 'store'

const { unit } = theme.grid

const Grid = () => {
  const isGridVisible = useSelector(store.selectGridIsVisible)
  const { stageX, stageY } = useSelector(store.selectStageCoord)

  if (!isGridVisible) return null

  const transform = `translate(${stageX - (stageX % unit) - unit},${stageY - (stageY % unit) - unit})`

  return (
    <rect data-testid={'Grid'} transform={transform} width={'110%'} height={'110%'} fill={'url(#grid)'} pointerEvents={'none'} />
  )
}

export default memo(Grid)