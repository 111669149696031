import { memo } from 'react'

import { IArrow, IArrowMapped } from 'types'
import { ContextMenuOption } from 'store/types'
import * as store from 'store'
import { useContextMenu } from 'hooks'

type ArrowContextMenuProps = IArrowMapped

const ArrowContextMenu = ({ id, to, from, type }: ArrowContextMenuProps) => {
  const dispatch = store.useAppDispatch()

  const updateArrow = (changes: Partial<IArrow>) => dispatch(store.updateArrow({ updates: { id, changes }, commitToHistory: true }))
  const swapArrowDirection = () => updateArrow({ from: to.id, to: from.id })
  const swapArrowType = () => updateArrow({ type: type === 'child' ? 'sibling' : 'child' })
  const deleteArrow = () => dispatch(store.deleteArrow(id))

  const groups: Array<Array<ContextMenuOption>> = [
    [
      { id: 'Arrow.commands.swapDirection', onClick: swapArrowDirection },
      { id: 'Arrow.commands.swapType', onClick: swapArrowType },
      { id: 'Arrow.commands.delete', onClick: deleteArrow },
    ],
  ]

  const menu = useContextMenu(groups)

  return <>{menu}</>;
}

export default memo(ArrowContextMenu)