import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { useClickOutside } from 'hooks'

const Listbox = styled.div`
  cursor: pointer;
`

const ListboxValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${theme.commandBar.height}px;
  width: 70px;
`

const ListboxItems = styled.ul`
  position: relative;
  background: ${theme.palette.primary.inverse};
  z-index: ${theme.zIndex.commandBar + 1};
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ListboxItem = styled.li`
  width: 70px;
  padding: 10px 2px 10px 8px;

  &:hover {
    color: ${theme.palette.secondary.inverse};
    background: ${theme.palette.secondary.main};
  }
`

const options = [10, 25, 50, 75, 100, 125, 150, 175, 200]

const ZoomListbox = () => {
  const { ref, hidden, setHidden } = useClickOutside()
  const dispatch = store.useAppDispatch()
  const zoom = useSelector(store.selectZoom)

  const setZoom = (value: number) => dispatch(store.setZoom({ value }))
  const toggleHidden = () => setHidden(!hidden)
  const label = Math.floor(zoom * 100)

  return (
    <Listbox
      data-testid={'ZoomListbox'}
      role={'listbox'}
      tabIndex={0}
      onPointerDown={toggleHidden}
      aria-label={'Select zoom percent'}
      aria-activedescendant={`ZoomListbox-option-${zoom}`}
      ref={ref}
    >
      <ListboxValue data-testid={'ZoomListbox-value'}>{label} %</ListboxValue>

      <ListboxItems hidden={hidden}>
        {options.map((option) => (
          <ListboxItem
            key={option}
            role={'option'}
            id={`ZoomListbox-option--${option}`}
            data-testid={`ZoomListbox-option-${option}`}
            aria-selected={option === label}
            onPointerDown={() => setZoom(Number(option) / 100)}
          >
            {option} %
          </ListboxItem>
        ))}
      </ListboxItems>
    </Listbox>
  )
}

export default ZoomListbox
