import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { rgba } from 'polished'

import theme from 'theme'
import { routes } from 'components/Router'

const Wrapper = styled.footer`
  height: ${theme.footer.height}px;
  padding: ${theme.spacing.unit / 3}px;
  background: ${theme.palette.primary.main};
`

const Version = styled.div`
  position: relative;
  float: right;
  right: ${theme.spacing.unit / 2}px;
  bottom: ${theme.spacing.unit}px;
  color: ${rgba(theme.palette.primary.inverse, 0.4)};
`

const NavItems = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;    
  margin: 0;
  height: 100%;
`

const NavItem = styled.li`
  cursor: pointer;
  padding: ${theme.spacing.unit / 4}px;
  margin: auto 0;

  &:not(:last-child) {
    border-right: 1px solid ${rgba(theme.palette.primary.inverse, 0.4)};
  }
`

const NavLink = styled(Link)`
  color: ${rgba(theme.palette.primary.inverse, 0.7)};
  padding: ${theme.spacing.unit / 4}px;

  &:hover {
    color: ${theme.palette.secondary.light};
  }
`

const Footer = () => {
  return (
    <Wrapper data-testid={'Footer'}>
      <NavItems>
        {routes
          .filter((x) => x.footerLink)
          .map((route) => (
            <NavItem key={route.key}>
              <NavLink to={route.path}>
                <FormattedMessage id={`${route.key}.title`} />
              </NavLink>
            </NavItem>
          ))}
      </NavItems>

      <Version>{process.env.REACT_APP_VERSION}</Version>
    </Wrapper>
  )
}

export default Footer