import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import * as store from 'store'
import { useI18n } from 'hooks'

const useTemplateLoader = () => {
  const { locale } = useI18n()
  const dispatch = store.useAppDispatch()
  const shouldLoad = useSelector(store.selectShouldLoadTemplates(locale))
  const isLoading = useSelector(store.selectTemplateIsLoading)
  const error = useSelector(store.selectTemplateError)

  useEffect(() => {
    if (shouldLoad) dispatch(store.loadTemplates(locale))
  }, [])

  return { isLoading, error }
}

export default useTemplateLoader