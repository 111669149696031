import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'

import Toast from './Toast'

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 1;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 300px;
    left: 50%;
    margin-left: -150px;
  }
`

const Toasts = () => {
  const dispatch = store.useAppDispatch()
  const ids = useSelector(store.toastSelectors.selectIds)

  const removeToast = (id: string | number) => dispatch(store.removeToast(id))

  return createPortal(
    <Wrapper data-testid={'Toasts'}>
      {ids.map((id) => (
        <Toast
          key={id}
          id={id}
          removeToast={removeToast}
        />
      ))}
    </Wrapper>,
    document.body
  )
}

export default Toasts
