import { createContext, useContext, useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'

import en from 'messages/en.json'
import { getStoredLocale, setStoredLocale } from 'store/localStorage'
import { IContext, IMessages } from './types'

export const initialContext = {
  locale: getStoredLocale(),
  messagesById: { en },
  isLoading: false,
  error: null,
  loadLocale: () => { },
}

const I18nContext = createContext<IContext>(initialContext)

type I18nProviderProps = {
  children: React.ReactNode
}

export const I18nProvider = (props: I18nProviderProps) => {
  const { children } = props
  const [locale, setLocale] = useState(initialContext.locale)
  const [messagesById, setMessages] = useState<IMessages>(initialContext.messagesById)
  const [isLoading, setLoading] = useState(initialContext.isLoading)
  const [error, setError] = useState(initialContext.error)

  const loadLocale = async (locale: string) => {
    if (!messagesById[locale] && !isLoading) {
      setLoading(true)

      try {
        const messagesModule = await import(`messages/${locale}.json`)
        setMessages(x => ({ ...x, [locale]: messagesModule.default }))
      } catch (err) {
        setError(err)
      }

      setLoading(false)
    }

    setLocale(locale)
    setStoredLocale(locale)
  }

  useEffect(() => {
    if (!messagesById[locale]) {
      loadLocale(locale)
    }
  }, [])

  const value = {
    locale,
    messagesById,
    isLoading,
    error,
    loadLocale,
  }

  return (
    <I18nContext.Provider value={value}>
      <I18nContext.Consumer>
        {({ locale, messagesById }: IContext) => (
          <IntlProvider locale={locale} messages={messagesById[locale] || en}>
            {children}
          </IntlProvider>
        )}
      </I18nContext.Consumer>
    </I18nContext.Provider>
  )
}

export const useI18n = () => useContext(I18nContext)

export default I18nContext
