import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SettingsState, Settings } from './types'
import { RootState } from './rootReducer'

export const initialSettingsState: SettingsState = {
  fontSize: '18',
  showPreviewFeatures: '',
  showDebugInfo: '',
}

const settings = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    setSetting(state, { payload }: PayloadAction<{ name: Settings; value: string }>) {
      const value = String(payload.value)
      ;(state[payload.name] as any) = value
      localStorage.setItem(payload.name, value)
    },
  },
})

export const { setSetting } = settings.actions

export const settingsReducer = settings.reducer

export const selectSettings = (state: RootState) => state.settings
export const selectFontSize = (state: RootState) => selectSettings(state).fontSize
