import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { CommandPanels } from 'store/types'
import { useClickOutside } from 'hooks'
import { BaseCardCommandPanel } from 'components/BaseCard'

const Wrapper = styled.div`
  position: absolute;
  background: ${theme.palette.primary.main};
  border-bottom: 1px solid ${theme.palette.primary.light};
  width: 100%;
  z-index: ${theme.zIndex.commandBar - 1};
  overflow: auto;
  top: ${theme.commandBar.height}px;
`

const panels: { [k in CommandPanels]: React.FC } = {
  BaseCardCommandPanel,
}

const CommandPanel = () => {
  const dispatch = store.useAppDispatch()
  const onDismiss = () => dispatch(store.hideCommandPanel())
  const { ref } = useClickOutside(onDismiss)
  const { name, props } = useSelector(store.selectCommandPanel)
  const C = name ? panels[name] : null

  if (!C || !props) return null

  return <Wrapper data-testid={'CommandPanel'} ref={ref}><C {...props} /></Wrapper>
}

export default CommandPanel