import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import axios from 'axios'

import { Template, TemplateState } from './types'
import { RootState } from './rootReducer'

const templateAdapter = createEntityAdapter<Template>()

export const initialTemplateState: TemplateState = {
  templates: templateAdapter.getInitialState(),
  locale: '',
  isLoading: true,
  error: null,
}

export const loadTemplates = createAsyncThunk<
  { locale: string; templates: Array<Template> },
  string
>('template/loadTemplates', async (locale: string) => {
  try {
    const templates = await axios.get<Array<Template>>(`/templates/${locale}.json`)
    return { locale, templates: templates.data }
  } catch (err) {
    throw err
  }
})

const template = createSlice({
  name: 'template',
  initialState: initialTemplateState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTemplates.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(loadTemplates.fulfilled, (state, { payload }) => {
      templateAdapter.setAll(state.templates, payload.templates)
      state.locale = payload.locale
      state.isLoading = false
    })
    builder.addCase(loadTemplates.rejected, (state, action) => {
      state.error = action.error.message
      state.isLoading = false
    })
  },
})

export const templateReducer = template.reducer

export const selectTemplate = (state: RootState) => state.template
export const selectTemplates = (state: RootState) => selectTemplate(state).templates
export const selectTemplateLocale = (state: RootState) => selectTemplate(state).locale
export const selectTemplateIsLoading = (state: RootState) => selectTemplate(state).isLoading
export const selectTemplateError = (state: RootState) => selectTemplate(state).error

export const {
  selectById: selectTemplateById,
  selectIds: selectTemplateIds,
  selectEntities: selectTemplateEntities,
  selectAll: selectAllTemplates,
  selectTotal: selectTotalTemplates,
} = templateAdapter.getSelectors<RootState>(selectTemplates)

export const selectTutorialTemplates = createSelector(selectAllTemplates, (templates) =>
  templates.filter((x) => x.type === 'tutorial' || x.type === 'lesson')
)
export const selectTemplateOptions = createSelector(selectAllTemplates, (templates) => [
  { label: 'None', value: '' },
  ...templates.filter((x) => x.type === 'template').map((x) => ({ label: x.name, value: x.id })),
])

export const selectShouldLoadTemplates = (locale: string) =>
  createSelector(
    selectTemplateLocale,
    (templateLocale) => !templateLocale || templateLocale !== locale
  )
