import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import theme from 'theme'

type Variants = keyof typeof variants

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  $variant?: Variants
  $borderless?: boolean
  as?: any
}

const variants = {
  primary: css`
    color: ${theme.palette.primary.main};
    border-color: ${theme.palette.primary.main};

    &:hover {
      color: ${theme.palette.primary.inverse};
      background: ${theme.palette.primary.main};
    }

    &:focus {
      color: ${theme.palette.primary.inverse};
      background: ${rgba(theme.palette.primary.main, 0.7)};
    }
  `,
  primaryInverse: css`
    color: ${theme.palette.primary.inverse};
    border-color: ${theme.palette.primary.inverse};

    &:hover {
      color: ${theme.palette.primary.main};
      background: ${theme.palette.primary.inverse};
    }

    &:focus {
      color: ${theme.palette.primary.main};
      background: ${rgba(theme.palette.primary.inverse, 0.7)};
    }
  `,
  secondary: css`
    color: ${theme.palette.secondary.main};
    border-color: ${theme.palette.secondary.main};

    &:hover {
      color: ${theme.palette.secondary.inverse};
      background: ${theme.palette.secondary.main};
    }

    &:focus {
      color: ${theme.palette.secondary.inverse};
      background: ${rgba(theme.palette.secondary.main, 0.7)};
    }
  `,
}

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid;
  text-decoration: none;
  padding: ${theme.spacing.unit / 3}px ${theme.spacing.unit / 1.5}px;
  text-transform: uppercase;
  transition: 0.2s ease;

  ${({ $variant }) => variants[$variant!]}
  ${({ $borderless }) => $borderless && { border: 'none' }}

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${theme.palette.disabled.main};
    border: ${theme.palette.disabled.main};
    background: transparent;
  }
`

const Button = ({ $variant = 'primary', type = 'button', ...props }: ButtonProps) => <StyledButton {...props} $variant={$variant} type={type} />

export default Button
