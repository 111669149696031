import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import theme from 'theme'
import { FormattedLinkButton } from 'components/Button'

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyMessage = styled.div`
  padding: ${theme.spacing.unit}px 0;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin: ${theme.spacing.unit}px 0;

  > * {
    margin: ${theme.spacing.unit / 2}px 0;
  }
`

const EmptyProjectList = () => {
  return (
    <Centered data-testid={'EmptyProjectList'}>
      <EmptyMessage>
        <FormattedMessage id={'EmptyProjectList.title'} tagName={'h2'} />
      </EmptyMessage>

      <Buttons>
        <FormattedLinkButton $variant={'secondary'} to={'/projects/add-tutorials'} id={'EmptyProjectList.addTutorials'} />
        <FormattedLinkButton $variant={'primaryInverse'} to={'/projects/new'} id={'EmptyProjectList.createProject'} />
      </Buttons>
    </Centered>
  )
}

export default EmptyProjectList