import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { buttons } from 'utils/mouse'
import { computeAngledPath, computeStraightPath } from 'utils/path'

type ArrowProps = {
  id: string
}

const { strokeWidth } = theme.arrow

const Group = styled.g`
    fill: none;
    cursor: pointer;
    user-select: none;
`

const Arrow = ({ id }: ArrowProps) => {
  const dispatch = store.useAppDispatch()
  const arrowMapped = useSelector(store.selectArrowMappedById(id))
  const isSelected = useSelector(store.selectIsSelected(id))
  const totalSelected = useSelector(store.selectTotalSelected)

  if (!arrowMapped) return null

  const { weight, type, style } = arrowMapped
  const isSibling = type === 'sibling'
  const markerStart = `url(#arrow-${weight}-start${isSelected ? '--selected' : ''})`
  const markerEnd = `url(#arrow-${weight}-end${isSelected ? '--selected' : ''})`
  const d = style === 'angled' ? computeAngledPath({ arrowMapped, isSibling }) : computeStraightPath({ arrowMapped, isSibling })

  const showContextMenu = (props: any) => dispatch(store.showContextMenu(props))
  const showPanel = (props: any) => dispatch(store.showPanel(props))
  const hidePanel = () => dispatch(store.hidePanel())
  const addSelected = () => dispatch(store.addSelected(id))
  const setSelected = (ids: string[]) => dispatch(store.setSelected(ids))
  const removeSelected = () => dispatch(store.removeSelected(id))

  const toggleSelection = (event: React.MouseEvent<any>) => {
    if (event.button !== 0) return

    let showPropertiesPanel = false
    if (isSelected) {
      if (event.shiftKey || event.ctrlKey) {
        showPropertiesPanel = totalSelected === 2
        removeSelected()
      } else {
        setSelected([])
      }
    } else {
      if (event.shiftKey || event.ctrlKey) {
        showPropertiesPanel = !totalSelected
        addSelected()
      } else {
        showPropertiesPanel = true
        setSelected([id])
      }
    }

    if (showPropertiesPanel) {
      showPanel({ name: 'ArrowPropertiesPanel', props: { id } })
    } else {
      hidePanel()
    }
  }

  const onPointerUp = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    if (event.button !== buttons.left) return
    toggleSelection(event)
  }

  const onContextMenu = (event: React.PointerEvent<SVGGElement>) => {
    event.preventDefault()

    showContextMenu({
      name: 'ArrowContextMenu',
      props: {
        ...arrowMapped,
        x: event.clientX,
        y: event.clientY,
      }
    })
  }

  return (
    <g>
      <Group data-testid={`Arrow-${id}`} onPointerUp={onPointerUp} onContextMenu={onContextMenu}>
        <path stroke={theme.palette.primary.dark} strokeWidth={Number(weight) + Number(strokeWidth)} d={d} />
        <path stroke={isSelected ? theme.palette.secondary.main : theme.palette.primary.inverse} strokeWidth={Number(weight)} d={d} markerEnd={markerEnd} {...(isSibling && { markerStart })} />
      </Group>
    </g>
  )
}

export default Arrow