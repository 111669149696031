import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { FormattedButton, FormattedLinkButton } from 'components/Button'

const Wrapper = styled.div`
  display: flex;
  transform: rotate(-90deg) translate(${theme.footer.height + theme.spacing.unit / 4}px, 100%);
  transform-origin: left bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  
  > * {
    margin: 0 ${theme.spacing.unit / 4}px;
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    visibility: hidden;
  }
`

const SideActions = () => {
  const dispatch = store.useAppDispatch()
  const showFeedbackModal = () => dispatch(store.showModal({ name: 'FeedbackModal', props: {} }))

  return (
    <Wrapper>
      <FormattedButton id={'Feedback.button'} onClick={showFeedbackModal} $variant={'primaryInverse'} />
      <FormattedLinkButton id={'Donate.button'} to={'/donate'} $variant={'secondary'} />
    </Wrapper>
  )
}

export default SideActions