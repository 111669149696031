import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LayoutState } from './types'
import { RootState } from './rootReducer'
import { deleteArrow, deleteCard } from './projectSlice'

export const initialLayoutState: LayoutState = {
  panel: {
    name: null,
    props: { id: null },
  },
  modal: {
    name: null,
    props: {},
  },
  commandPanel: {
    name: null,
    props: {},
  },
  contextMenu: {
    name: null,
    props: { x: 0, y: 0, options: [] },
  },
}

const layout = createSlice({
  name: 'layout',
  initialState: initialLayoutState,
  reducers: {
    showPanel(state, { payload }: PayloadAction<typeof initialLayoutState.panel>) {
      state.panel = payload.props?.id !== state.panel.props?.id ? payload : initialLayoutState.panel
      state.commandPanel = initialLayoutState.commandPanel
    },
    hidePanel(state) {
      state.panel = initialLayoutState.panel
    },
    showModal(state, { payload }: PayloadAction<typeof initialLayoutState.modal>) {
      state.modal = payload
    },
    hideModal(state) {
      state.modal = initialLayoutState.modal
    },
    showCommandPanel(state, { payload }: PayloadAction<typeof initialLayoutState.commandPanel>) {
      state.commandPanel = payload
      state.panel = initialLayoutState.panel
    },
    hideCommandPanel(state) {
      state.commandPanel = initialLayoutState.commandPanel
    },
    showContextMenu(state, { payload }: PayloadAction<typeof initialLayoutState.contextMenu>) {
      state.contextMenu = payload
    },
    hideContextMenu(state) {
      state.contextMenu = initialLayoutState.contextMenu
    },
    resetLayout(state) {
      state.panel = initialLayoutState.panel
      state.modal = initialLayoutState.modal
      state.commandPanel = initialLayoutState.commandPanel
      state.contextMenu = initialLayoutState.contextMenu
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteArrow, (state, { payload }) => {
      state.panel = initialLayoutState.panel
    })
    builder.addCase(deleteCard, (state, action) => {
      state.panel = initialLayoutState.panel
    })
  },
})

export const {
  showPanel,
  hidePanel,
  showModal,
  hideModal,
  showCommandPanel,
  hideCommandPanel,
  showContextMenu,
  hideContextMenu,
  resetLayout,
} = layout.actions

export const layoutReducer = layout.reducer

export const selectLayout = (state: RootState) => state.layout
export const selectPanel = (state: RootState) => selectLayout(state).panel
export const selectModal = (state: RootState) => selectLayout(state).modal
export const selectCommandPanel = (state: RootState) => selectLayout(state).commandPanel
export const selectContextMenu = (state: RootState) => selectLayout(state).contextMenu
