import { ICard, Rect } from 'types'
import theme from 'theme'
import { distance2d } from './math'

export const getSelectionBounds = (selection: Rect): [number, number, number, number] => [
  selection.x,
  selection.y,
  selection.x + selection.width,
  selection.y + selection.height,
]

export const getCardBounds = (card: ICard): [number, number, number, number] => [
  card.x,
  card.y,
  card.x + theme.card.width,
  card.y + theme.card.height,
]

export const getCommonCardsBounds = (cards: readonly ICard[]): [number, number, number, number] => {
  if (!cards.length) {
    return [0, 0, 0, 0]
  }

  let minX = Infinity
  let maxX = -Infinity
  let minY = Infinity
  let maxY = -Infinity

  cards.forEach((card) => {
    const [x1, y1, x2, y2] = getCardBounds(card)
    minX = Math.min(minX, x1)
    minY = Math.min(minY, y1)
    maxX = Math.max(maxX, x2)
    maxY = Math.max(maxY, y2)
  })

  return [minX, minY, maxX, maxY]
}

export const getClosestCardBounds = (
  cards: readonly ICard[],
  from: { x: number; y: number }
): [number, number, number, number] => {
  if (!cards.length) {
    return [0, 0, 0, 0]
  }

  let minDistance = Infinity
  let closestCard = cards[0]

  cards.forEach((card) => {
    const [x1, y1, x2, y2] = getCardBounds(card)
    const distance = distance2d((x1 + x2) / 2, (y1 + y2) / 2, from.x, from.y)

    if (distance < minDistance) {
      minDistance = distance
      closestCard = card
    }
  })

  return getCardBounds(closestCard)
}
