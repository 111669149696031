import { combineReducers } from '@reduxjs/toolkit'

import { baseCardsReducer } from './baseCardsSlice'
import { gridReducer } from './gridSlice'
import { layoutReducer } from './layoutSlice'
import { projectReducer } from './projectSlice'
import { selectionReducer } from './selectionSlice'
import { settingsReducer } from './settingsSlice'
import { templateReducer } from './templateSlice'
import { toastsReducer } from './toastsSlice'

export const rootReducer = combineReducers({
  baseCards: baseCardsReducer,
  grid: gridReducer,
  layout: layoutReducer,
  project: projectReducer,
  selection: selectionReducer,
  settings: settingsReducer,
  template: templateReducer,
  toasts: toastsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
