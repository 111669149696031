import { memo } from 'react'
import { useSelector } from 'react-redux'

import * as store from 'store'

import Arrow from './Arrow'
import NewArrow from './NewArrow'

const ArrowLayer = () => {
  const ids = useSelector(store.selectArrowIdsOrdered)
  const sourceId = useSelector(store.selectSourceId)

  return (
    <>
      {ids.map(id => <Arrow key={id} id={id as string} />)}
      {sourceId && <NewArrow />}
    </>
  )
}

export default memo(ArrowLayer)