import styled from 'styled-components'

import theme from 'theme'
import { useI18nFormatter } from 'hooks'
import Page from 'components/Page'

const Header = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.fontSize * 3.5}px;
`

const CallToActionSection = styled.div`
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;
  padding: ${theme.spacing.unit / 2}px;

  @media (min-width: ${theme.breakpoints.lg}px) {
    max-width: ${theme.breakpoints.md}px;
  }
`

const CallToAction = styled.div`
  padding: ${theme.spacing.unit / 2}px;
`

const VendorSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const Vendor = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.unit}px;
  text-align: center;
`

const QRC = styled.img`
  width: ${theme.donate.qrcSize}px;
  height: ${theme.donate.qrcSize}px;
  padding: ${theme.spacing.unit}px;
`

const ScanToDonate = styled.div``

const VendorName = styled.div`
  font-size: ${props => props.theme.fontSize * 2.5}px;
  color: ${theme.palette.secondary.light};
  font-weight: bold;
`

const AccountName = styled.div`
  padding: ${theme.spacing.unit / 6}px;
  color: ${theme.palette.primary.xxlight};
`

const DonatePage = () => {
  const { fm } = useI18nFormatter()

  return (
    <Page fluid>
      <Header>{fm('DonatePage.title')}</Header>

      <CallToActionSection>
        <CallToAction>{fm('DonatePage.message1')}</CallToAction>
        <CallToAction>{fm('DonatePage.message2')}</CallToAction>
        <CallToAction>{fm('DonatePage.message3')}</CallToAction>
      </CallToActionSection>

      <VendorSection>
        <Vendor>
          <QRC src={'/images/aows_qrc_venmo.png'} alt={`${fm('DonatePage.scanToDonate')} Venmo`} />
          <ScanToDonate>{fm('DonatePage.scanToDonate')}</ScanToDonate>
          <VendorName>Venmo</VendorName>
          <AccountName>@ArtOfWarStrategies</AccountName>
        </Vendor>

        <Vendor>
          <QRC src={'/images/aows_qrc_cashapp.png'} alt={`${fm('DonatePage.scanToDonate')} Cash App`} />
          <ScanToDonate>{fm('DonatePage.scanToDonate')}</ScanToDonate>
          <VendorName>Cash App</VendorName>
          <AccountName>$ArtOfWarStrategies</AccountName>
        </Vendor>

        <Vendor>
          <QRC src={'/images/aows_qrc_paypal.png'} alt={`${fm('DonatePage.scanToDonate')} PayPal`} />
          <ScanToDonate>{fm('DonatePage.scanToDonate')}</ScanToDonate>
          <VendorName>PayPal</VendorName>
          <AccountName>paypal.me/Artofwarstrategies</AccountName>
        </Vendor>
      </VendorSection>
    </Page>
  )
}

export default DonatePage
