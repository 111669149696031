import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { rgba } from 'polished'

import theme from 'theme'
import { useMediaQuery } from 'hooks'
import { routes } from 'components/Router'

import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'
import MobileMenuButton from './MobileMenuButton'

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  height: ${theme.header.height}px;
  background: ${theme.palette.primary.inverse};
  box-shadow: 0 1px 2px 0 ${rgba(theme.palette.primary.main, 0.1)};
  z-index: ${theme.zIndex.header};
  color: ${theme.palette.primary.main};
  display: flex;
  padding: 0;
  width: 100%;
`

const Section = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: ${theme.header.height}px;
  padding: 0 ${theme.spacing.unit / 2}px;
`

const Branding = styled(Link)`
  display: flex;
  margin-right: auto;
  align-items: center;
  text-decoration: none;
  color: ${theme.palette.primary.dark};

  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

const Logo = styled.img`
  height: ${theme.header.height - theme.spacing.unit / 4}px;
  width: ${theme.header.height - theme.spacing.unit / 4}px;
  margin-right: ${theme.spacing.unit / 4}px;
`

const BrandName = styled.div`
  padding: ${theme.spacing.unit / 4}px;
  font-style: oblique;
  font-size: 24px;
`

const Mobile = styled.div``

const Desktop = styled.div`
  margin-left: auto;
`

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const smallViewport = useMediaQuery(`(max-width:${theme.breakpoints.md}px)`)
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen)

  // close mobile menu when screen gets bigger
  useEffect(() => {
    if (!smallViewport) {
      setMobileMenuOpen(false)
    }
  }, [smallViewport])

  return (
    <Wrapper data-testid={'Header'}>
      <Section>
        <Branding to={'/'}>
          <Logo src={'/images/aows-logo-light.png'} alt={'Art of War Strategies logo'} />
          <BrandName>Art of War Strategies</BrandName>
        </Branding>

        {smallViewport && (
          <Mobile data-testid={'Header-mobile'}>
            <MobileMenuButton isOpen={mobileMenuOpen} onClick={toggleMobileMenu} />
            {mobileMenuOpen && <MobileMenu routes={routes} onDismiss={toggleMobileMenu} />}
          </Mobile>
        )}
      </Section>

      {!smallViewport && (
        <Section>
          <Desktop data-testid={'Header-desktop'}>
            <DesktopMenu routes={routes} />
          </Desktop>
        </Section>
      )}
    </Wrapper>
  )
}

export default Header
