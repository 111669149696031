import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { IconContext } from 'react-icons'

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31245
import * as _ from 'styled-components/cssprop' // eslint-disable-line @typescript-eslint/no-unused-vars

import App from 'components/App'
import { I18nProvider } from 'components/I18n'
import { Toasts } from 'components/Toast'
import * as serviceWorker from './serviceWorker'
import { store } from './store';

ReactDOM.render(
  <StrictMode>
    <RouterProvider>
      <IconContext.Provider value={{ size: '28px' }}>
        <I18nProvider>
          <Provider store={store}>
            <App />
            <Toasts />
          </Provider>
        </I18nProvider>
      </IconContext.Provider>
    </RouterProvider>
  </StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
