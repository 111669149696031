import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'
import axios from 'axios'

import { IBaseCard, IBaseCardFile } from 'types'
import { BaseCardState } from './types'
import { RootState } from './rootReducer'
import { getCardQuote } from 'utils/cardUtils'

const baseCardsAdapter = createEntityAdapter<IBaseCard>()

export const initialBaseCardState: BaseCardState = {
  ...baseCardsAdapter.getInitialState(),
  idsByCategory: {},
  randomQuoteIndexes: {},
  locale: '',
  isLoading: true,
  error: null,
  cardPriorityMap: {},
}

export const loadBaseCards = createAsyncThunk<IBaseCardFile, string>(
  'baseCard/loadBaseCards',
  async (locale: string) => {
    try {
      const res = await axios.get(`/baseCards/${locale}.json`)
      return res.data as IBaseCardFile
    } catch (err) {
      throw err
    }
  }
)

export const getRandomQuoteIndexes = (state: BaseCardState) => {
  const randomQuoteIndexes = {} as { [quoteId: string]: string }

  state.ids.forEach((id) => {
    const baseCard = state.entities[id]!
    const randomIndex = Math.floor(Math.random() * Object.keys(baseCard.quotes).length)
    randomQuoteIndexes[baseCard.id] = Object.keys(baseCard.quotes)[randomIndex]
  })

  return randomQuoteIndexes
}

//Returns card priority type, See generatebasecards (CARD_PRIORITY) - KKobayashi Feb 21,2021
export const getCardPriorityMap = (state: BaseCardState) => {
  const cardPriorityMap = {} as { [priorityId: string]: number }

  state.ids.forEach((id) => {
    const baseCard = state.entities[id]!

    if (!cardPriorityMap[baseCard.priority]) {
      cardPriorityMap[baseCard.priority] = 1
    } else {
      cardPriorityMap[baseCard.priority] += 1
    }
    // assigning card id => priorit because the priority isnt popping up in the cards stored
    // in the actual project cards itself. This is for easier mapping when looking at what
    // cards we have currently in the project and their priority. - J_Somers Feb 21, 2021
    cardPriorityMap[baseCard.id] = baseCard.priority
  })

  return cardPriorityMap
}

const baseCards = createSlice({
  name: 'baseCards',
  initialState: initialBaseCardState,
  reducers: {
    generateRandomQuoteIndexes(state) {
      state.randomQuoteIndexes = getRandomQuoteIndexes(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBaseCards.pending, (state) => {
      state.error = null
    })
    builder.addCase(loadBaseCards.fulfilled, (state, { payload }) => {
      const { baseCards, idsByCategory, locale } = payload
      baseCardsAdapter.setAll(state, baseCards)
      state.idsByCategory = idsByCategory
      state.randomQuoteIndexes = getRandomQuoteIndexes(state)
      state.locale = locale
      state.isLoading = false
      state.cardPriorityMap = getCardPriorityMap(state)
    })
    builder.addCase(loadBaseCards.rejected, (state, action) => {
      state.error = action.error.message
      state.isLoading = false
    })
  },
})

export const { generateRandomQuoteIndexes } = baseCards.actions

export const baseCardsReducer = baseCards.reducer

export const selectBaseCards = (state: RootState) => state.baseCards
export const selectBaseCardIdsByCategory = (state: RootState) =>
  selectBaseCards(state).idsByCategory
export const selectBaseCardsLocale = (state: RootState) => selectBaseCards(state).locale
export const selectBaseCardsIsLoading = (state: RootState) => selectBaseCards(state).isLoading
export const selectBaseCardsError = (state: RootState) => selectBaseCards(state).error

export const {
  selectById: selectBaseCardById,
  selectIds: selectBaseCardIds,
  selectEntities: selectBaseCardEntities,
  selectAll: selectAllBaseCards,
  selectTotal: selectTotalBaseCards,
} = baseCardsAdapter.getSelectors<RootState>((state) => selectBaseCards(state))

export const selectBaseCardsByCategory = createSelector(
  selectBaseCardEntities,
  selectBaseCardIdsByCategory,
  (baseCards, idsByCategory) => {
    const baseCardsByCategory: { [category: string]: Array<IBaseCard> } = {}

    Object.entries(idsByCategory).forEach(([category, ids]) => {
      baseCardsByCategory[category] = ids.map((id) => baseCards[id]!)
    })

    return baseCardsByCategory
  }
)

export const selectRandomQuoteIndexes = (state: RootState) =>
  selectBaseCards(state).randomQuoteIndexes
export const selectRandomQuoteIndex = (baseCardId: string) =>
  createSelector(selectRandomQuoteIndexes, (quoteIds) => quoteIds[baseCardId])

export const selectRandomQuote = createSelector(selectAllBaseCards, (baseCards) => {
  if (!baseCards.length) return ''

  const baseCardIndex = Math.floor(Math.random() * baseCards.length)
  const baseCard = baseCards[baseCardIndex]
  if (!baseCard || !baseCard.quotes) return ''

  const quoteKeys = Object.keys(baseCard.quotes)
  const quoteKeyIndex = Math.floor(Math.random() * quoteKeys.length)

  return getCardQuote(baseCard.quotes, quoteKeys[quoteKeyIndex])
})

export const selectShouldLoadBaseCards = (locale: string) =>
  createSelector(
    selectBaseCardsLocale,
    (baseCardsLocale) => !baseCardsLocale || baseCardsLocale !== locale
  )
