import { ICard, Rect } from 'types'
import { getCardBounds, getSelectionBounds } from './bounds'

export const getCardsWithinSelection = (cards: readonly ICard[], selection: Rect) => {
  const [selectionX1, selectionY1, selectionX2, selectionY2] = getSelectionBounds(selection)

  return cards.filter((card) => {
    const [x1, y1, x2, y2] = getCardBounds(card)
    
    return selectionX1 <= x1 && selectionY1 <= y1 && selectionX2 >= x2 && selectionY2 >= y2
  })
}
