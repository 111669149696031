import styled from 'styled-components'

import theme from 'theme'

type CategoryListProps = {
  categories: string[],
  selected: string
  setSelected: (category: string) => void
}

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: ${theme.palette.primary.light};
`

export const ListItem = styled.li<{ $isSelected: boolean }>`
  padding: ${theme.spacing.unit}px;
  background: ${({ $isSelected }) => $isSelected ? theme.palette.primary.dark : theme.palette.primary.light};
  border-right: 2px solid ${({ $isSelected }) => $isSelected ? theme.palette.primary.inverse : theme.palette.primary.xlight};
  cursor: pointer;
  
  &:hover {
    background: ${theme.palette.primary.main};
  }
`

const CategoryList = ({ categories, selected, setSelected }: CategoryListProps) => {
  return (
    <List>
      {categories.map((category) => {
        const onPointerDown = () => setSelected(category)

        return (
          <ListItem key={category} $isSelected={selected === category} onPointerDown={onPointerDown}>
            {category}
          </ListItem>
        )
      })}
    </List>
  )
}

export default CategoryList