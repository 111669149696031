import {
  createAction,
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  PayloadAction,
  Draft,
  Update,
} from '@reduxjs/toolkit'
import shortid from 'shortid'
import axios from 'axios'

import {
  IArrow,
  IArrowMapped,
  ICard,
  ICardMapped,
  IBaseCard,
  IProjectSummary,
  Coords,
  Dimensions,
  FlooredNumber,
} from 'types'
import { ProjectState, ProjectDetailState, StageState, HistoryState, Template } from './types'
import { getNormalizedZoom, ZOOM_AMOUNT } from 'utils/zoom'
import { isCardVisible } from 'utils/visiblity'
import { exportJSON } from 'utils/file'
import { RootState } from './rootReducer'
import { saveStoredState } from './localStorage'
import { selectBaseCardEntities } from './baseCardsSlice'
import { showModal } from './layoutSlice'
import { selectTutorialTemplates, selectShouldLoadTemplates } from './templateSlice'

export const MAX_HISTORY = 10
export const COUNTDOWN_TIME = 5

const projectAdapter = createEntityAdapter<ProjectDetailState>()
const arrowsAdapter = createEntityAdapter<IArrow>()
const cardsAdapter = createEntityAdapter<ICard>()

// defaults

export const initialProjectState: ProjectState = {
  selectedProjectId: null,
  projects: projectAdapter.getInitialState(),
  sourceId: null,
  targetId: null,
  dimensions: { width: window.innerWidth, height: window.innerHeight },
  isLoading: false,
  error: null,
  timeLeft: COUNTDOWN_TIME,
  timeLimit: COUNTDOWN_TIME,
  blockTimer: false,
}

export const getHistoryDefault = (props: Partial<HistoryState> = {}) => ({
  current: 0,
  max: 0,
  saved: 0,
  entries: [{ arrows: arrowsAdapter.getInitialState(), cards: cardsAdapter.getInitialState() }],
  ...props,
})

export const getStageDefault = (props: Partial<StageState> = {}) => ({
  x: 0 as FlooredNumber,
  y: 0 as FlooredNumber,
  zoom: 1,
  ...props,
})

export const getProjectDefault = (props: Partial<ProjectDetailState> = {}) => ({
  id: shortid.generate(),
  name: null,
  arrows: arrowsAdapter.getInitialState(),
  cards: cardsAdapter.getInitialState(),
  history: getHistoryDefault(props.history),
  stage: getStageDefault(props.stage),
  isLoading: false,
  template: '',
  ...props,
})

export const getArrowDefault = (props: Partial<IArrow> = {}): IArrow => ({
  id: shortid.generate(),
  to: '',
  from: '',
  type: 'child',
  weight: 15,
  style: 'straight',
  ...props,
})

export const getCardDefault = (props: Partial<ICard> = {}): ICard => ({
  id: shortid.generate(),
  baseId: '',
  quoteId: '',
  name: '',
  notes: '',
  priority: -1,
  z: 0,
  x: 0 as FlooredNumber,
  y: 0 as FlooredNumber,
  ...props,
})

export const getMappedCardDefault = (props: Partial<IBaseCard & ICard> = {}) => ({
  ...getBaseCardDefault(),
  ...getCardDefault(),
  ...props,
})

export const getBaseCardDefault = (props: Partial<IBaseCard> = {}): IBaseCard => ({
  id: shortid.generate(),
  front: '',
  title: '',
  priority: -1,
  category: '',
  description: '',
  quotes: {},
  placeholders: { name: [], notes: [] },
  imageSrc: '',
  ...props,
})

export const addTutorials = createAsyncThunk<void, string>(
  'project/addTutorials',
  async (locale, thunkApi) => {
    const state = thunkApi.getState() as RootState

    // locale may be different from what is in state base off what user selected.
    // we want to keep our templateSlice in sync with the language select and
    // seperately load the passed locale if needed
    let tutorials: Array<Template>
    if (selectShouldLoadTemplates(locale)) {
      try {
        const templates = await axios.get<Array<Template>>(`/templates/${locale}.json`)
        tutorials = templates.data.filter((x) => x.type === 'tutorial' || x.type === 'lesson')
      } catch (err) {
        throw err
      }
    } else {
      tutorials = selectTutorialTemplates(state)
    }

    const tutorialProjects = tutorials.map(({ id, ...rest }) =>
      getProjectDefault({ ...rest, template: id! })
    )
    thunkApi.dispatch(addManyProjects(tutorialProjects))
  }
)

export const deleteSelected = createAsyncThunk<void>('project/deleteSelected', (_, thunkApi) => {
  const state = thunkApi.getState() as RootState
  const selectedIds = Object.keys(state.selection.selected)
  thunkApi.dispatch(deleteManyCards(selectedIds))
})

export const saveProject = createAsyncThunk<boolean>('project/saveProject', (_, thunkApi) => {
  const state = thunkApi.getState() as RootState
  saveStoredState(state)

  const project = selectSelectedProject(state)
  const canSave = selectCanSave(state)
  if (!project || !canSave) return false
  return true
})

export const printProject = createAsyncThunk<void>('project/printProject', (_, thunkApi) => {
  window.alert('Not Implemented')
})

export const suggestCard = createAsyncThunk<void>('project/suggestCard', (_, thunkApi) => {
  const state = thunkApi.getState() as RootState
  const { cardPriorityMap } = state.baseCards
  const currProjectPriorityMap = buildProjectPriorityMap(state)
  const priority = Object.keys(currProjectPriorityMap)
  let cardSuggestion = undefined
  // lower the level, the higher the priority i.e. 0 are root nodes
  for (let i = 0; i < priority.length; i++) {
    // if we dont have n - 1 of current level, suggest a card from that section that
    // doesn't already exist in the project.
    if (currProjectPriorityMap[i].length < cardPriorityMap[i] - 1) {
      cardSuggestion = findSuggestion(currProjectPriorityMap[i], cardPriorityMap, i)
      console.log('suggested: ' + cardSuggestion)
      break
    }
  }

  if (cardSuggestion) {
    thunkApi.dispatch(showModal({ name: 'SuggestCardModal', props: { baseCardId: cardSuggestion } }))
  } else {
    // no suggestion found, do a error pop up or say we dont know
    //addToast({ text: fm('Toasts.NoSuggestions'), variant: 'success' })
    console.log('No suggestions Code: 200')
  }
})

export const exportProject = createAsyncThunk<boolean, string>(
  'project/exportProject',
  (id, thunkApi) => {
    const state = thunkApi.getState() as RootState
    saveStoredState(state)
    const { history, isLoading, ...data } = selectProjectById(state, id)!
    exportJSON(data, `${data.name}_${Date.now()}.aows`)
    return true
  }
)

// project helpers

const getSelectedProject = (state: ProjectState) => {
  return state.selectedProjectId ? state.projects.entities[state.selectedProjectId] : null
}

const withSelectedProject = <T>(
  updater: (project: ProjectDetailState, action: PayloadAction<T>) => void
) => (state: Draft<ProjectState>, action: PayloadAction<T>) => {
  // any action the user does resets the timer
  resetSuggestionTimer(state)
  const project = getSelectedProject(state)
  if (!project) return
  return updater(project, action)
}

const withSelectedProjectNoPayload = (updater: (project: ProjectDetailState) => void) => (
  state: Draft<ProjectState>
) => {
  const project = getSelectedProject(state)
  if (!project) return
  return updater(project)
}

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max))
}

/*
 Creates a map of the priority with what cards we currently have for that priority level
    {

      0: [baseIds...]
    }
  */
const buildProjectPriorityMap = (state: any) => {
  const { cardPriorityMap } = state.baseCards
  const currProjectPriorityMap = {} as { [priority: string]: Array<string> }

  // count all cards and create a map based on existing cards and their priorities
  const currentProject = state.project.projects.entities[state.project.selectedProjectId!]
  const cardIds = currentProject!.cards.ids
  const cards = currentProject!.cards.entities
  cardIds.forEach((cardId: string) => {
    const card = cards[cardId]
    const { baseId } = card!
    const cardPriority = cardPriorityMap[baseId]

    // store the baseIds so we know what types we have
    if (!currProjectPriorityMap[cardPriority]) {
      currProjectPriorityMap[cardPriority] = [baseId]
    } else {
      if (!currProjectPriorityMap[cardPriority].includes(baseId)) {
        currProjectPriorityMap[cardPriority].push(baseId)
      }
    }
  })

  // we have priority levels 0-4, if we are missing any cards from a given
  // level, we need to initialize it to an empty array so we know to suggest
  // something from that level
  for (let i = 0; i < 5; i++) {
    if (!currProjectPriorityMap[i]) {
      currProjectPriorityMap[i] = []
    }
  }

  return currProjectPriorityMap
}

// return a card baseId that is not in the current cards array at the desired priority level
const findSuggestion = (currCards: any, cardMap: any, level: number) => {
  const suggestionCards = []
  for (const baseId in cardMap) {
    // ignore the keys with priority levels and its max number of unique cards in that level
    if (isNaN(baseId as any)) {
      // add cards to suggest if they match the priority level desired
      // and we don't already have the card on the board (for now)
      if (cardMap[baseId] === level && !currCards.includes(baseId)) {
        suggestionCards.push(baseId)
      }
    }
  }
  // if we have no cards to suggest
  if (!suggestionCards.length) {
    return undefined
  }
  // randomly choose a card from the suggestion cards array
  return suggestionCards[getRandomInt(suggestionCards.length)]
}

// zoom helpers

export const setZoom = createAction<{ value: number; pointer?: Coords }>('setZoom')
export const updateZoom = createAction<{ delta: number; pointer?: Coords }>('updateZoom')

const setZoomState = (project: ProjectDetailState, newZoom: number, pointer?: Coords) => {
  const normalizedZoom = getNormalizedZoom(newZoom)
  const offset = pointer ? pointer : { x: window.innerWidth / 2, y: window.innerHeight / 2 }
  project.stage.x = (project.stage.x +
    offset.x / project.stage.zoom -
    offset.x / normalizedZoom) as FlooredNumber
  project.stage.y = (project.stage.y +
    offset.y / project.stage.zoom -
    offset.y / normalizedZoom) as FlooredNumber
  project.stage.zoom = normalizedZoom
}

// history helpers

const increaseCurrentHistory = (project: ProjectDetailState) => {
  project.history.current =
    project.history.current < MAX_HISTORY ? project.history.current + 1 : MAX_HISTORY
}

const decreaseCurrentHistory = (project: ProjectDetailState) => {
  project.history.current = project.history.current > 0 ? project.history.current - 1 : 0
}

const setStateFromHistory = (project: ProjectDetailState) => {
  const historyEntry = project.history.entries[project.history.current]
  project.arrows = historyEntry.arrows
  project.cards = historyEntry.cards
}

//Implemented for auto suggest timer Reset Function - KKobayashi Feb 21, 2021
const resetSuggestionTimer = (project: ProjectState) => {
  project.blockTimer = false
  project.timeLeft = COUNTDOWN_TIME
}

const addHistoryEntry = (project: ProjectDetailState) => {
  const start = project.history.current >= MAX_HISTORY ? 1 : 0
  project.history.entries = project.history.entries.slice(start, project.history.current + 1)
  project.history.entries.push({ arrows: project.arrows, cards: project.cards })
  increaseCurrentHistory(project)
  project.history.max = project.history.current
}

const initializeHistory = (project: ProjectDetailState) => {
  project.history.entries = [{ arrows: project.arrows, cards: project.cards }]
}

const project = createSlice({
  name: 'project',
  initialState: initialProjectState,
  reducers: {
    // PROJECT
    setSelectedProjectId(state, { payload }: PayloadAction<string>) {
      state.selectedProjectId = payload
      resetSuggestionTimer(state)
    },
    setDimensions(state, { payload }: PayloadAction<Dimensions>) {
      state.dimensions = payload
    },
    addProject(state, { payload }: PayloadAction<ProjectDetailState>) {
      if (payload.template) {
        initializeHistory(payload)
      }
      projectAdapter.addOne(state.projects, payload)
    },
    addManyProjects(state, { payload }: PayloadAction<Array<ProjectDetailState>>) {
      payload.forEach((project) => {
        if (project.template) {
          initializeHistory(project)
        }
      })
      projectAdapter.addMany(state.projects, payload)
    },
    editProject(state, { payload }: PayloadAction<Update<IProjectSummary>>) {
      projectAdapter.updateOne(state.projects, payload)
    },
    deleteProject(state, { payload }: PayloadAction<string>) {
      projectAdapter.removeOne(state.projects, payload)
    },
    copyProject(state, { payload }: PayloadAction<string>) {
      const project = state.projects.entities[payload]
      if (!project) return

      const { id, name, ...rest } = project
      const copy = { ...rest, name: `${name} - copy` }
      projectAdapter.addOne(state.projects, getProjectDefault(copy))
    },
    //Implemented for auto suggest timer Reset Function - KKobayashi Feb 21, 2021
    countdown(state) {
      if (state.timeLeft > 0) {
        state.timeLeft -= 1
      }
      console.log('countdown...' + state.timeLeft)
      if (!state.timeLeft) {
        // block the timer from counting down until they dismiss the suggestion.
        // when they dismiss the suggestion, reset the timer
        // resetSuggestionTimer(state)
        state.blockTimer = true
        console.log('Blocked the timer')
        // unlock semaphore
      }
    },
    // ARROW
    addArrow: withSelectedProject<Partial<IArrow>>((project, { payload }) => {
      arrowsAdapter.addOne(project.arrows, getArrowDefault(payload))
      addHistoryEntry(project)
    }),
    updateArrow: withSelectedProject<{ updates: Update<IArrow>; commitToHistory?: boolean }>(
      (project, { payload }) => {
        const { commitToHistory, updates } = payload
        arrowsAdapter.updateOne(project.arrows, updates)
        if (commitToHistory) addHistoryEntry(project)
      }
    ),
    deleteArrow: withSelectedProject<string>((project, { payload }) => {
      arrowsAdapter.removeOne(project.arrows, payload)
      addHistoryEntry(project)
    }),
    // CARD
    addCard: withSelectedProject<Partial<ICard>>((project, { payload }) => {
      const card = getCardDefault(payload)
      card.z = project.cards.ids.length
      cardsAdapter.addOne(project.cards, card)
      addHistoryEntry(project)
    }),
    updateCard: withSelectedProject<{ updates: Update<ICard>; commitToHistory?: boolean }>(
      (project, { payload }) => {
        const { commitToHistory, updates } = payload
        cardsAdapter.updateOne(project.cards, updates)
        if (commitToHistory) addHistoryEntry(project)
      }
    ),
    updateManyCards: withSelectedProject<{ updates: Update<ICard>[]; commitToHistory?: boolean }>(
      (project, { payload }) => {
        const { commitToHistory, updates } = payload
        cardsAdapter.updateMany(project.cards, updates)
        if (commitToHistory) addHistoryEntry(project)
      }
    ),
    deleteCard: withSelectedProject<string>((project, { payload }) => {
      const arrowIds = Object.entries(project.arrows.entities)
        .filter(([k, v]) => v?.from === payload || v?.to === payload)
        .map(([k, v]) => k)

      cardsAdapter.removeOne(project.cards, payload)
      arrowsAdapter.removeMany(project.arrows, arrowIds)
      addHistoryEntry(project)
    }),
    deleteManyCards: withSelectedProject<string[]>((project, { payload }) => {
      const cardIds = project.cards.ids.filter((id) => payload.includes(id as string))

      const arrowIds = Object.entries(project.arrows.entities)
        .filter(
          ([k, v]) =>
            (v && cardIds.includes(v.from)) ||
            (v && cardIds.includes(v.to)) ||
            payload.includes(k as string)
        )
        .map(([k, v]) => k)

      if (!cardIds.length && !arrowIds.length) return

      cardsAdapter.removeMany(project.cards, cardIds)
      arrowsAdapter.removeMany(project.arrows, arrowIds)
      addHistoryEntry(project)
    }),
    bringCardForward: withSelectedProject<string>((project, { payload }) => {
      const { ids, entities } = project.cards
      const index = ids.indexOf(payload)
      // not found or nothing to bring behind
      if (index === -1 || index > ids.length - 2) return
      // swap passed card and next's z position
      const nextIndex = index + 1
      const oldCard = entities[ids[index]]!
      project.cards.entities[ids[index]]!.z = entities[ids[nextIndex]]!.z
      project.cards.entities[ids[nextIndex]]!.z = oldCard.z
      project.cards.ids[index] = ids[nextIndex]
      project.cards.ids[nextIndex] = oldCard.id
      addHistoryEntry(project)
    }),
    bringCardToFront: withSelectedProject<string>((project, { payload }) => {
      const { ids, entities } = project.cards
      const index = ids.indexOf(payload)
      // not found or nothing to bring behind
      if (index === -1 || index > ids.length - 2) return
      // swap passed card and last's z position
      const lastIndex = ids.length - 1
      project.cards.entities[ids[index]]!.z = entities[ids[lastIndex]]!.z + 1
      project.cards.ids.push(ids.splice(index, 1)[0])
      addHistoryEntry(project)
    }),
    sendCardBackward: withSelectedProject<string>((project, { payload }) => {
      const { ids, entities } = project.cards
      const index = ids.indexOf(payload)
      // not found or nothing to send back
      if (index < 1) return
      // swap passed card and previous's z position
      const previousIndex = index - 1
      const oldCard = entities[ids[index]]!
      project.cards.entities[ids[index]]!.z = entities[ids[previousIndex]]!.z
      project.cards.entities[ids[previousIndex]]!.z = oldCard.z
      project.cards.ids[index] = ids[previousIndex]
      project.cards.ids[previousIndex] = oldCard.id
      addHistoryEntry(project)
    }),
    sendCardToBack: withSelectedProject<string>((project, { payload }) => {
      const { ids, entities } = project.cards
      const index = ids.indexOf(payload)
      // not found or nothing to send back
      if (index < 1) return
      // swap passed card and last's z position
      const firstIndex = 0
      project.cards.entities[ids[index]]!.z = entities[ids[firstIndex]]!.z - 1
      project.cards.ids.unshift(ids.splice(index, 1)[0])
      addHistoryEntry(project)
    }),
    // TARGETS
    setSourceId(state, { payload }: PayloadAction<string | null>) {
      state.sourceId = payload
    },
    setTargetId(state, { payload }: PayloadAction<string | null>) {
      state.targetId = payload
    },
    // ZOOM
    zoomIn: withSelectedProject<{ pointer?: Coords } | undefined>((project, { payload }) => {
      setZoomState(project, project.stage.zoom + ZOOM_AMOUNT, payload?.pointer)
    }),
    zoomOut: withSelectedProject<{ pointer?: Coords } | undefined>((project, { payload }) => {
      setZoomState(project, project.stage.zoom - ZOOM_AMOUNT, payload?.pointer)
    }),
    resetZoom: withSelectedProject<{ pointer?: Coords } | undefined>((project, { payload }) => {
      setZoomState(project, getStageDefault().zoom, payload?.pointer)
    }),
    updateStage: withSelectedProject<Partial<StageState>>((project, { payload }) => {
      project.stage = { ...project.stage, ...payload }
    }),
    // HISTORY
    undo: withSelectedProjectNoPayload((project) => {
      if (project.history.current === 0) return
      decreaseCurrentHistory(project)
      setStateFromHistory(project)
    }),
    redo: withSelectedProjectNoPayload((project) => {
      if (project.history.current === project.history.max) return
      increaseCurrentHistory(project)
      setStateFromHistory(project)
    }),
  },
  extraReducers: (builder) => {
    // PROJECT
    builder.addCase(
      saveProject.fulfilled,
      withSelectedProjectNoPayload((project) => {
        project.history.saved = project.history.current
      })
    )
    builder.addCase(addTutorials.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(addTutorials.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(addTutorials.rejected, (state, action) => {
      state.error = action.error.message
      state.isLoading = false
    })
    // ZOOM
    builder.addCase(
      setZoom,
      withSelectedProject((project, { payload }) => {
        setZoomState(project, payload.value, payload.pointer)
      })
    )
    builder.addCase(
      updateZoom,
      withSelectedProject((project, { payload }) => {
        setZoomState(project, project.stage.zoom + payload.delta, payload.pointer)
      })
    )
  },
})

export const {
  setSelectedProjectId,
  setDimensions,
  addProject,
  addManyProjects,
  editProject,
  deleteProject,
  copyProject,
  addArrow,
  updateArrow,
  deleteArrow,
  addCard,
  updateCard,
  updateManyCards,
  deleteCard,
  deleteManyCards,
  bringCardForward,
  bringCardToFront,
  sendCardBackward,
  sendCardToBack,
  setSourceId,
  setTargetId,
  zoomIn,
  zoomOut,
  resetZoom,
  updateStage,
  undo,
  redo,
  countdown,
} = project.actions

export const projectReducer = project.reducer

export const selectProject = (state: RootState) => state.project
export const selectSelectedProjectId = (state: RootState) => selectProject(state).selectedProjectId
export const selectBlockTimer = (state: RootState) => selectProject(state).blockTimer
export const selectDimensions = (state: RootState) => selectProject(state).dimensions
export const selectProjectListIsLoading = (state: RootState) => selectProject(state).isLoading
export const selectProjectListError = (state: RootState) => selectProject(state).error

export const {
  selectById: selectProjectById,
  selectIds: selectProjectIds,
  selectEntities: selectProjectEntities,
  selectAll: selectAllProjects,
  selectTotal: selectTotalProjects,
} = projectAdapter.getSelectors<RootState>((state) => state.project.projects)

export const selectSelectedProject = createSelector(
  selectSelectedProjectId,
  selectProjectEntities,
  (id, projects) => (id ? projects[id] : null)
)

export const selectProjectName = createSelector(selectSelectedProject, (project) => project?.name)
export const selectProjectSummary = createSelector(selectSelectedProject, (project) => ({
  name: project?.name,
  template: project?.template,
}))

export const {
  selectById: selectCardById,
  selectIds: selectCardIds,
  selectEntities: selectCardEntities,
  selectAll: selectAllCards,
  selectTotal: selectTotalCards,
} = cardsAdapter.getSelectors<RootState>((state) => {
  const project = selectSelectedProject(state)
  return project ? project.cards : cardsAdapter.getInitialState()
})

export const selectAllCardsMapped = createSelector(
  selectAllCards,
  selectBaseCardEntities,
  (cards, baseCards) => {
    return cards.map((card) => ({
      ...baseCards[card.baseId],
      ...card,
    })) as ICardMapped[]
  }
)

export const selectCardMappedById = (cardId: string) =>
  createSelector(
    (state) => selectCardById(state, cardId),
    selectBaseCardEntities,
    (card, baseCards) => {
      if (!card || !card.baseId) return null
      const baseCard = getBaseCardDefault(baseCards[card.baseId])
      return { ...baseCard, ...card } as ICardMapped
    }
  )

export const {
  selectById: selectArrowById,
  selectIds: selectArrowIds,
  selectEntities: selectArrowEntities,
  selectAll: selectAllArrows,
  selectTotal: selectTotalArrows,
} = arrowsAdapter.getSelectors<RootState>((state) => {
  const project = selectSelectedProject(state)
  return project ? project.arrows : arrowsAdapter.getInitialState()
})

export const selectArrowIdsOrdered = createSelector(
  selectCardEntities,
  selectAllArrows,
  (cards, arrows) =>
    arrows.sort((a, b) => cards[a.from]!.z - cards[b.from]!.z).map((arrow) => arrow.id)
)

export const selectArrowMappedById = (arrowId: string) =>
  createSelector(
    (state) => selectArrowById(state, arrowId),
    selectCardEntities,
    (arrow, cards) => {
      if (!arrow || !arrow.to || !arrow.from) return null
      const to = cards[arrow.to]
      const from = cards[arrow.from]
      return { ...arrow, to, from } as IArrowMapped
    }
  )

export const selectSourceId = (state: RootState) => state.project.sourceId
export const selectTargetId = (state: RootState) => state.project.targetId

export const selectStage = createSelector(selectSelectedProject, (project) =>
  getStageDefault(project?.stage)
)
export const selectZoom = createSelector(selectStage, (stage) => (stage ? stage.zoom : 1))
export const selectStageCoord = createSelector(selectStage, (stage) => ({
  stageX: stage ? stage.x : 0,
  stageY: stage ? stage.y : 0,
}))
export const selectIsAnyCardVisible = createSelector(
  selectStage,
  selectAllCards,
  selectDimensions,
  (stage, cards, dimensions) =>
    stage && (!cards.length || cards.some((card) => isCardVisible(card, stage, dimensions)))
)

export const selectHistory = createSelector(selectSelectedProject, (project) => project?.history)
export const selectCanUndo = createSelector(
  selectHistory,
  (history) => history && history.current > 0
)
export const selectCanRedo = createSelector(
  selectHistory,
  (history) => history && history.current !== history.max
)
export const selectCanSave = createSelector(
  selectHistory,
  (history) => history && history.current !== history.saved
)
