import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { getStoredState } from './localStorage'
import { rootReducer } from './rootReducer'

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: getStoredState(),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
