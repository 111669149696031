import { SerializableState } from './types'
import { initialProjectState } from './projectSlice'
import { RootState } from './rootReducer'

export const ignoreLocalStorage = () => {
  localStorage.setItem('ignore-localStorage', 'true')
}

export const getSerializableState = (state: SerializableState | RootState | null) => {
  if (!state) return undefined

  const { grid, settings, project } = state

  const serializableProject = project
    ? { ...initialProjectState, projects: project.projects }
    : initialProjectState

  const serializableState = {
    grid,
    settings,
    project: serializableProject,
  }

  return serializableState
}

export const getStoredState = () => {
  try {
    if (localStorage.getItem('ignore-localStorage')) {
      localStorage.clear()
      return undefined
    }

    const serializedState = localStorage.getItem('state')

    if (serializedState) {
      return getSerializableState(JSON.parse(serializedState))
    }
  } catch (err) {
    console.warn('Error, failed to get stored state: ', err)
  }

  return undefined
}

// saves the redux state for rehydration. Do not store non persistent
// states such as error messages and loading status, or objects
// that could change / are not user specific such as templates
export const saveStoredState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(getSerializableState(state))
    localStorage.setItem('state', serializedState)
  } catch {
    // ignore write errors / ensure it doesnt get stuck in a bad state
    localStorage.removeItem('state')
  }
}

export const getStoredLocale = () => localStorage.getItem('locale') || 'en'
export const setStoredLocale = (locale: string) => localStorage.setItem('locale', locale || 'en')
