import { FormattedMessage } from 'react-intl'

import LinkButton, { LinkButtonProps } from './LinkButton'

export type FormattedLinkButtonProps = LinkButtonProps & {
  id: string
}

const FormattedLinkButton = ({ id, ...rest }: FormattedLinkButtonProps) => {
  return (
    <LinkButton {...rest}>
      <FormattedMessage id={id} />
    </LinkButton>
  )
}

export default FormattedLinkButton