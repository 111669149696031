import { Route, Switch, Redirect } from 'react-router-dom'

import { routes } from './routes'

const Router = () => (
  <Switch>
    {routes.map(route => (
      <Route {...route} />
    ))}

    <Redirect from={'*'} to={'/'} />
  </Switch>
)

export default Router
