import styled from 'styled-components'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { RiArrowLeftCircleLine } from 'react-icons/ri'

import theme from 'theme'
import * as store from 'store'

type CardQuickActionsProps = {
  id: string
  show: boolean
}

const { width, borderWidth, padding } = theme.card
const { actionSize } = theme.cardAction

const BoundingBox = styled.rect`
  fill: none;
  pointer-events: all;
`

const AddArrowIcon = styled(RiArrowLeftCircleLine)`
  cursor: pointer;
  pointer-events: bounding-box;
  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

const DeleteCardIcon = styled(IoIosCloseCircleOutline)`
  cursor: pointer;
  pointer-events: bounding-box;
  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

const CardQuickActions = ({ id, show }: CardQuickActionsProps) => {
  const dispatch = store.useAppDispatch()

  const setSourceId = () => dispatch(store.setSourceId(id))
  const deleteCard = () => dispatch(store.deleteCard(id))

  const onClickAddArrow = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    setSourceId()
  }

  return (
    <g data-testid={`CardQuickActions-${id}`}>
      <BoundingBox
        x={0 - actionSize - padding - borderWidth}
        y={0 - borderWidth}
        width={width + actionSize + padding + (borderWidth * 2)}
        height={borderWidth + actionSize * 2 + padding}
      />

      {show && (
        <>
          <AddArrowIcon
            data-testid={'CardQuickActions-add-arrow'}
            x={0 - actionSize - padding}
            y={0}
            size={actionSize}
            onPointerUp={onClickAddArrow}
            aria-label={'Add Arrow'}
          />

          <DeleteCardIcon
            data-testid={'CardQuickActions-delete-card'}
            x={0 - actionSize - padding}
            y={actionSize + padding}
            size={actionSize}
            onPointerUp={deleteCard}
            aria-label={'Delete'}
          />
        </>
      )}
    </g>
  )
}

export default CardQuickActions