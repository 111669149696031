import { useState } from 'react'
import styled from 'styled-components'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import { IOption } from 'types'
import theme from 'theme'
import { useClickOutside, useI18n, useI18nFormatter } from 'hooks'
import { keys } from 'utils/event'
import { locales, localeOptions } from 'utils/locale'

const Listbox = styled.div`
  position: relative;
  cursor: pointer;
`

const ListboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.unit / 2}px;
  width: 100%;
  height: 50px;
`

const ListboxItems = styled.ul`
  position: absolute;
  background: ${theme.palette.primary.inverse};
  z-index: ${theme.zIndex.commandBar + 1};
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

const ListboxItem = styled.li`
  padding: ${theme.spacing.unit / 4}px ${theme.spacing.unit / 2}px;
  margin: 0;

  &[aria-selected="true"] {
    color: ${theme.palette.secondary.inverse};
    background: ${theme.palette.secondary.main};
  }

  &:hover:not([aria-selected="true"]) {
    color: ${theme.palette.secondary.main};
  }
`

const DownArrow = styled(IoIosArrowDown)`
  pointer-events: none;
`

const UpArrow = styled(IoIosArrowUp)`
  pointer-events: none;
`

const getOptionIndex = (locale: string) => localeOptions.findIndex(x => x.value === locale) || 0

const LanguageSelect = () => {
  const { locale, loadLocale } = useI18n()
  const { fm } = useI18nFormatter()
  const { ref, hidden, setHidden } = useClickOutside()
  const [selected, setSelected] = useState(getOptionIndex(locale))

  const toggleHidden = () => {
    setHidden(!hidden)
    setSelected(getOptionIndex(locale))
  }

  const onChange = (option: IOption) => () => {
    loadLocale(option.value)
    toggleHidden()
  }

  const onListboxKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === keys.escape) {
      if (!hidden) {
        toggleHidden()
      }
    }

    if (event.key === keys.enter) {
      toggleHidden()

      const selectedLocale = localeOptions[selected].value
      if (!hidden && locale !== selectedLocale) {
        loadLocale(selectedLocale)
      }
    }

    if (event.key === keys.arrowDown) {
      if (hidden) {
        toggleHidden()
      } else {
        setSelected(selected + 1 > localeOptions.length - 1 ? 0 : selected + 1)
      }
    }

    if (event.key === keys.arrowUp) {
      if (hidden) {
        toggleHidden()
      } else {
        setSelected(selected - 1 < 0 ? localeOptions.length - 1 : selected - 1)
      }
    }
  }

  return (
    <Listbox
      role={'listbox'}
      tabIndex={0}
      onPointerUp={toggleHidden}
      onKeyDown={onListboxKeyDown}
      aria-label={fm('I18n.selectLanguage')}
      aria-activedescendant={`language-listitem-${locale}`}
      ref={ref}
    >
      <ListboxLabel>
        {locales[locale]}
        {hidden ? <DownArrow size={20} /> : <UpArrow size={20} />}
      </ListboxLabel>

      <ListboxItems hidden={hidden}>
        {localeOptions.map((option, i) => (
          <ListboxItem
            key={option.value}
            role={'option'}
            id={`language-listitem-${option.value}`}
            aria-selected={i === selected}
            onPointerUp={onChange(option)}
          >
            {option.label}
          </ListboxItem>
        ))}
      </ListboxItems>
    </Listbox>
  )
}

export default LanguageSelect
