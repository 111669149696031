import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import * as store from 'store'
import { useI18n } from 'hooks'

const useBaseCardLoader = () => {
  const { locale } = useI18n()
  const dispatch = store.useAppDispatch()
  const shouldLoad = useSelector(store.selectShouldLoadBaseCards(locale))
  const isLoading = useSelector(store.selectBaseCardsIsLoading)
  const error = useSelector(store.selectBaseCardsError)

  useEffect(() => {
    if (shouldLoad) dispatch(store.loadBaseCards(locale))
  }, [])

  return { isLoading, error }
}

export default useBaseCardLoader 