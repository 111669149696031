import theme from 'theme'

const { unit } = theme.grid

export const GridDefs = () => (
  <defs>
    <pattern id={'grid'} width={unit} height={unit} patternUnits={'userSpaceOnUse'}>
      <path d={`M ${unit} 0 L 0 0 0 ${unit}`} fill={'none'} stroke={theme.palette.primary.inverse} strokeWidth={0.8} opacity={1} />
    </pattern>
  </defs>
)

export default GridDefs