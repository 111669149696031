import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ICard, IArrow, FlooredNumber } from 'types'
import theme from 'theme'
import * as store from 'store'
import { useMousePosition } from 'hooks'
import { hasIntersection, computeStraightPath } from 'utils/path'

const Overlay = styled.rect`
  fill: none;
  cursor: none;
  pointer-events: all;
`

const bboxSize = 40

const NewArrow = () => {
  const dispatch = store.useAppDispatch()
  const mouse = useMousePosition()
  const zoom = useSelector(store.selectZoom)
  const { stageX, stageY } = useSelector(store.selectStageCoord)
  const cardList = useSelector(store.selectAllCards)
  const sourceId = useSelector(store.selectSourceId) as string
  const targetId = useSelector(store.selectTargetId) as string
  const sourceCard = useSelector(store.selectCardMappedById(sourceId))

  const [offset, setOffset] = useState({ x: mouse.x, y: mouse.y })
  const [target, setTarget] = useState<ICard | null>(null)

  useEffect(() => {
    const x = (stageX + mouse.x / zoom) - (bboxSize / 2)
    const y = (stageY + mouse.y / zoom) - (bboxSize / 2)
    setOffset({ x, y })
  }, [mouse])

  useEffect(() => {
    const hasIntersect = cardList.some((card) => {
      if (card.id === sourceCard?.id) return false

      if (hasIntersection(card.x, card.y, theme.card.width, theme.card.height, offset.x, offset.y, bboxSize, bboxSize)) {
        if (targetId !== card.id) {
          setTarget(card)
          setTargetId(card.id)
        }
        return true
      }
      return false
    })

    if (!hasIntersect && targetId) {
      setTarget(null)
      setTargetId(null)
    }
  }, [sourceCard, targetId, offset])

  if (!sourceCard) return null

  const defaultArrow = store.getArrowDefault()
  const markerEnd = `url(#arrow-${defaultArrow.weight}-end--selected)`
  const toPoints = target ? target : { x: Math.floor(offset.x) as FlooredNumber, y: Math.floor(offset.y) as FlooredNumber }

  const d = target
    ? computeStraightPath({
      arrowMapped: {
        ...store.getArrowDefault(),
        from: sourceCard,
        to: store.getMappedCardDefault(toPoints),
      }, isSibling: false
    })
    : `M ${sourceCard.x + (theme.card.width / 2)} ${sourceCard.y + (theme.card.height / 2)} ${offset.x + (bboxSize / 2)} ${offset.y + (bboxSize / 2)}`

  const clearSourceId = () => dispatch(store.setSourceId(null))
  const setTargetId = (id: string | null) => dispatch(store.setTargetId(id))
  const addArrow = (values: Partial<IArrow>) => dispatch(store.addArrow(values))

  const onPointerUp = (event: React.MouseEvent<any>) => {
    event.stopPropagation()

    if (target) {
      addArrow({ from: sourceId, to: target.id })
      setTargetId(null)
    }

    clearSourceId()
  }

  return (
    <g data-testid={'NewArrow'}>
      <path
        stroke={theme.palette.secondary.main}
        strokeWidth={defaultArrow.weight}
        d={d}
        markerEnd={markerEnd}
        opacity={0.7}
      />

      <Overlay
        x={stageX}
        y={stageY}
        width={'100%'}
        height={'100%'}
        onPointerUp={onPointerUp}
      />
    </g>
  )
}

export default memo(NewArrow)
