import { IArrowMapped } from 'types'

import theme from 'theme'

const { width, height, padding } = theme.card
const { strokeWidth } = theme.arrow

type ComputePathProps = {
  arrowMapped: IArrowMapped
  isSibling: boolean
}

export const getIntersection = (
  dx: number,
  dy: number,
  cx: number,
  cy: number,
  w: number,
  h: number,
  markerAdjacent: number,
  markerOpposite: number
) => {
  if (Math.abs(dy / dx) < h / w) {
    return [cx + (dx > 0 ? w : -w) + markerAdjacent, cy + (dy * w) / Math.abs(dx) + markerOpposite]
  }

  return [cx + (dx * h) / Math.abs(dy) + markerAdjacent, cy + (dy > 0 ? h : -h) + markerOpposite]
}

export const hasIntersection = (
  x1: number,
  y1: number,
  w1: number,
  h1: number,
  x2: number,
  y2: number,
  w2: number,
  h2: number
) => {
  return !(x2 > x1 + w1 || x2 + w2 < x1 || y2 > y1 + h1 || y2 + h2 < y1)
}

export const computeAngledPath = ({ arrowMapped, isSibling }: ComputePathProps) => {
  const { to, from, weight } = arrowMapped
  const isLeft = from.x + width < to.x
  const isRight = from.x > to.x + width
  const isAbove = from.y + height < to.y
  const isBelow = from.y > to.y + height
  const markerWidth = weight * 2 + strokeWidth
  const endMarkerWidth = isSibling ? markerWidth : 0

  let start = { x: 0, y: 0 }
  let middle = { x: 0, y: 0 }

  if (isLeft) {
    start = { x: from.x + width + endMarkerWidth, y: from.y + height / 2 }
    middle = {
      x: (to.x + start.x - markerWidth) / 2 - start.x,
      y: to.y + height / 2 - start.y,
    }
    return `M ${start.x} ${start.y} h ${middle.x} v ${middle.y} h ${middle.x}`
  } else if (isRight) {
    start = { x: from.x - endMarkerWidth, y: from.y + height / 2 }
    middle = {
      x: (to.x + width + start.x + markerWidth) / 2 - start.x,
      y: to.y + height / 2 - start.y,
    }
    return `M ${start.x} ${start.y} h ${middle.x} v ${middle.y} h ${middle.x}`
  } else if (isBelow) {
    start = { x: from.x + width / 2, y: from.y - endMarkerWidth }
    middle = {
      x: to.x + width / 2 - start.x,
      y: (to.y + height + start.y + markerWidth) / 2 - start.y,
    }
    return `M ${start.x} ${start.y} v ${middle.y} h ${middle.x} v ${middle.y}`
  } else if (isAbove) {
    start = { x: from.x + width / 2, y: from.y + height + endMarkerWidth }
    middle = {
      x: to.x + width / 2 - start.x,
      y: (to.y + start.y - markerWidth) / 2 - start.y,
    }
    return `M ${start.x} ${start.y} v ${middle.y} h ${middle.x} v ${middle.y}`
  }

  return `M ${start.x} ${start.y} h ${middle.x} v ${middle.y} h ${middle.x}`
}

export const computeStraightPath = ({ arrowMapped, isSibling }: ComputePathProps) => {
  const { from, to } = arrowMapped
  const markerWidth = arrowMapped.weight * 2 + strokeWidth
  // Half widths and half heights
  const halfWidth = width / 2
  const halfHeight = height / 2
  // Center coordinates
  const cx1 = from.x + halfWidth
  const cy1 = from.y + halfHeight
  const cx2 = to.x + halfWidth
  const cy2 = to.y + halfHeight
  // Distance between centers
  const dx = cx2 - cx1
  const dy = cy2 - cy1

  const angle = Math.atan2(to.y - from.y, to.x - from.x)
  const markerAdjacent = Math.cos(angle) * markerWidth
  const markerOpposite = Math.sin(angle) * markerWidth
  const markerStartX = isSibling ? markerAdjacent : -(dx % width) / padding
  const markerStartY = isSibling ? markerOpposite : -(dy % height) / padding

  const p1 = getIntersection(dx, dy, cx1, cy1, halfWidth, halfHeight, markerStartX, markerStartY)
  const p2 = getIntersection(
    -dx,
    -dy,
    cx2,
    cy2,
    halfWidth,
    halfHeight,
    -markerAdjacent,
    -markerOpposite
  )

  return `M ${p1[0]} ${p1[1]} ${p2[0]} ${p2[1]}`
}
