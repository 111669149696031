import styled from 'styled-components'

import theme from 'theme'
import { List, ListItem } from './ProjectList'

const placeholders = [180, 120, 220, 160, 190]

const ItemPlaceholder = styled.div<{ width: number }>`
  background: ${theme.palette.disabled.light};
  height: 21px;
  width: ${({ width }) => width}px;
  margin: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
`

const ProjectListLoading = () => {
  return (
    <List data-testid={'ProjectListLoading'}>
      {placeholders.map((size) => (
        <ListItem key={size} data-testid={`ProjectListLoading-item-${size}`}>
            <ItemPlaceholder width={size} />
        </ListItem>
      ))}
    </List>
  )
}

export default ProjectListLoading
