import { memo } from 'react'

import { ContextMenuOption } from 'store/types'
import * as store from 'store'
import { useContextMenu } from 'hooks'

type CardContextMenuProps = {
  id: string
}

const CardContextMenu = ({ id }: CardContextMenuProps) => {
  const dispatch = store.useAppDispatch()

  const setSourceId = () => dispatch(store.setSourceId(id))
  const bringCardForward = () => dispatch(store.bringCardForward(id))
  const bringCardToFront = () => dispatch(store.bringCardToFront(id))
  const sendCardBackward = () => dispatch(store.sendCardBackward(id))
  const sendCardToBack = () => dispatch(store.sendCardToBack(id))
  const deleteCard = () => dispatch(store.deleteCard(id))

  const groups: Array<Array<ContextMenuOption>> = [
    [
      { id: 'Card.commands.addArrow', onClick: setSourceId },
    ],
    [
      { id: 'Card.commands.bringForward', onClick: bringCardForward },
      { id: 'Card.commands.bringToFront', onClick: bringCardToFront },
      { id: 'Card.commands.sendBackward', onClick: sendCardBackward },
      { id: 'Card.commands.sendToBack', onClick: sendCardToBack },
    ],
    [
      { id: 'Card.commands.delete', onClick: deleteCard },
    ],
  ]

  const menu = useContextMenu(groups)

  return <>{menu}</>;
}

export default memo(CardContextMenu)