import { Route } from 'types'
import AddTutorialsPage from 'pages/AddTutorialsPage'
import DonatePage from 'pages/DonatePage'
import EditProjectPage from 'pages/EditProjectPage'
import ErrorPage from 'pages/ErrorPage'
import HomePage from 'pages/HomePage'
import LegalPage from 'pages/LegalPage'
import NewProjectPage from 'pages/NewProjectPage'
import ProductsPage from 'pages/ProductsPage'
import ProjectDashboardPage from 'pages/ProjectDashboardPage'
import ProjectPage from 'pages/ProjectPage'

export const pages: { [key: string]: Route } = {
  ErrorPage: {
    key: 'ErrorPage',
    exact: true,
    path: '/error',
    component: ErrorPage,
  },
  HomePage: {
    key: 'HomePage',
    exact: true,
    path: '/',
    component: HomePage,
  },
  DonatePage: {
    key: 'DonatePage',
    exact: true,
    footerLink: true,
    path: '/donate',
    component: DonatePage,
  },
  ProductsPage: {
    key: 'ProductsPage',
    exact: true,
    headerLinkDesktop: true,
    headerLinkMobile: true,
    path: '/products',
    component: ProductsPage,
  },
  ProjectDashboardPage: {
    key: 'ProjectDashboardPage',
    exact: true,
    headerLinkDesktop: true,
    headerLinkMobile: true,
    path: '/projects',
    component: ProjectDashboardPage,
  },
  NewProjectPage: {
    key: 'NewProjectPage',
    exact: true,
    path: '/projects/new',
    component: NewProjectPage,
  },
  AddTutorialsPage: {
    key: 'AddTutorialsPage',
    exact: true,
    path: '/projects/add-tutorials',
    component: AddTutorialsPage,
  },
  EditProjectPage: {
    key: 'EditProjectPage',
    exact: true,
    path: '/projects/:id/edit',
    component: EditProjectPage,
  },
  ProjectPage: {
    key: 'ProjectPage',
    exact: true,
    path: '/projects/:id',
    component: ProjectPage,
  },
  LegalPage: {
    key: 'LegalPage',
    exact: true,
    headerLinkDesktop: true,
    headerLinkMobile: true,
    path: '/legal',
    component: LegalPage,
  },
  TermsAndConditions: {
    key: 'LegalPage.terms',
    exact: true,
    footerLink: true,
    path: '/legal',
    component: LegalPage,
  },
}

export const routes = Object.values(pages)
