import { createSlice } from '@reduxjs/toolkit'

import { GridState } from './types'
import { RootState } from './rootReducer'

export const initialGridState: GridState = {
  isVisible: false,
  isSnapping: false,
}

const grid = createSlice({
  name: 'grid',
  initialState: initialGridState,
  reducers: {
    toggleGridVisible(state) {
      state.isVisible = !state.isVisible
    },
    toggleGridSnapping(state) {
      state.isSnapping = !state.isSnapping
    },
  },
})

export const { toggleGridVisible, toggleGridSnapping } = grid.actions

export const gridReducer = grid.reducer

export const selectGridIsVisible = (state: RootState) => state.grid.isVisible
export const selectGridIsSnapping = (state: RootState) => state.grid.isSnapping
