import { ICard, Dimensions } from 'types'
import { StageState } from 'store/types'
import { getCardBounds } from './bounds'

// determines if card is within boundaries of scaled dimensions of stage
export const isCardVisible = (card: ICard, stage: StageState, dimensions: Dimensions) => {
  const [x1, y1, x2, y2] = getCardBounds(card)

  return (
    x1 <= stage.x + dimensions.width / stage.zoom &&
    x2 >= stage.x &&
    y1 <= stage.y + dimensions.height / stage.zoom &&
    y2 >= stage.y
  )
}
