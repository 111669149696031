import { createGlobalStyle } from 'styled-components'

import theme from 'theme'

type GlobalStyleProps = {
  $fontSize: string
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>` 
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${theme.palette.primary.inverse};
    background: ${theme.palette.primary.dark};
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
    font-size: ${(props) => props.$fontSize}px;
  }

  h1 {
    font-size: ${(props) => Number(props.$fontSize) * 2}px;
  }

  h1 {
    font-size: ${(props) => Math.floor(Number(props.$fontSize) * 1.75)}px;
  }

  h2 {
    font-size: ${(props) => Math.floor(Number(props.$fontSize) * 1.5)}px;
  }

  h3 {
    font-size: ${(props) => Math.floor(Number(props.$fontSize) * 1.25)}px;
  }

  #root {
    height: 100%;
  }

  img,
  image {
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: ${(props) => props.$fontSize}px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.primary.xxlight};
    border: ${(props) => Math.floor(Number(props.$fontSize) / 3)}px solid ${theme.palette.primary.main};
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track-piece,
  ::-webkit-scrollbar-corner {
    background: ${theme.palette.primary.main};
  }

  * {
    box-sizing: border-box;
  }
`

export default GlobalStyle
