import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { IoIosClose } from 'react-icons/io'
import { rgba } from 'polished'

import theme from 'theme'
import * as store from 'store'
import { Modals } from 'store/types'
import { useClickOutside } from 'hooks'
import { AddCardModal, SuggestCardModal } from 'components/Card'
import { FeedbackModal } from 'components/Feedback'

const Overlay = styled.div`
  position: fixed;
  display: flex;
  z-index: ${theme.zIndex.modal};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${rgba(theme.palette.primary.dark, 0.9)};
`

const Content = styled.div`
  background-color: ${theme.palette.primary.dark};
  margin: auto;
  padding: ${theme.spacing.unit}px;
  border: 1px solid ${theme.palette.primary.xlight};
  width: 80%;
  max-width: 900px;
  min-width: 300px;
`

const DismissButton = styled.button`
  float: right;
  background: none;
  color: ${theme.palette.primary.inverse};
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: ${theme.palette.secondary.main};
  }
`

const modals: { [k in Modals]: React.FC<any> } = {
  AddCardModal,
  FeedbackModal,
  SuggestCardModal
}

const Modal = () => {
  const dispatch = store.useAppDispatch()
  const hideModal = () => dispatch(store.hideModal())
  const onDismiss = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    hideModal()
  }

  const { ref } = useClickOutside(onDismiss)
  const { name, props } = useSelector(store.selectModal)
  const C = name ? modals[name] : null

  if (!C || !props) return null

  // stop other layouts from auto closing
  const stopPropagation = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
  }

  return (
    <Overlay data-testid={'Modal-Overlay'} onPointerUp={onDismiss}>
      <Content data-testid={'Modal-Content'} ref={ref} onPointerUp={stopPropagation}>
        <DismissButton aria-label={'dismiss'} onPointerUp={onDismiss}>
          <IoIosClose size={36} />
        </DismissButton>

        <C {...props} />
      </Content>
    </Overlay>
  )
}

export default Modal