import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'

import { Rect } from 'types'
import { SelectedState, SelectionState } from './types'
import { RootState } from './rootReducer'

export const initialSelectionState: SelectionState = {
  isSelecting: false,
  rect: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  selected: {},
}

const selection = createSlice({
  name: 'selection',
  initialState: initialSelectionState,
  reducers: {
    setSelectionRect(state, { payload }: PayloadAction<Rect>) {
      state.isSelecting = true
      state.rect = payload
    },
    clearSelectionRect(state) {
      state.isSelecting = false
      state.rect = initialSelectionState.rect
    },
    addSelected(state, { payload }: PayloadAction<string>) {
      state.selected[payload] = true
    },
    addManySelected(state, { payload }: PayloadAction<string[]>) {
      payload.forEach((id) => {
        state.selected[id] = true
      })
    },
    removeSelected(state, { payload }: PayloadAction<string>) {
      delete state.selected[payload]
    },
    removeManySelected(state, { payload }: PayloadAction<string[]>) {
      payload.forEach((id) => {
        delete state.selected[id]
      })
    },
    setSelected(state, { payload }: PayloadAction<string[]>) {
      const selected = payload.reduce((obj, id) => {
        obj[id] = true
        return obj
      }, {} as SelectedState)
      state.selected = selected
    },
  },
})

export const {
  setSelectionRect,
  clearSelectionRect,
  addSelected,
  addManySelected,
  removeSelected,
  removeManySelected,
  setSelected,
} = selection.actions

export const selectionReducer = selection.reducer

export const selectSelection = (state: RootState) => state.selection
export const selectIsSelecting = (state: RootState) => state.selection.isSelecting
export const selectSelected = (state: RootState) => state.selection.selected
export const selectSelectionRect = createSelector(selectSelection, (state) => state.rect)
export const selectSelectedIds = createSelector(selectSelected, (selected) => Object.keys(selected))
export const selectIsSelected = (id: string) =>
  createSelector(selectSelected, (selected) => Boolean(selected[id]))
export const selectTotalSelected = createSelector(selectSelectedIds, (ids) => ids.length)
