import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Route } from 'types'
import theme from 'theme'
import { useI18nFormatter } from 'hooks'
import { LanguageSelect } from 'components/I18n'
import { SettingsButton } from 'components/Settings'

export type DesktopMenuProps = {
  routes: Route[]
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const NavItems = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  cursor: pointer;
  color: ${({ $isActive }) => $isActive ? theme.palette.secondary.main : theme.palette.primary.main};
  text-decoration: none;
  border-bottom: 2px solid transparent;
  padding: ${theme.spacing.unit / 4}px;
  margin: 0 ${theme.spacing.unit / 4}px;

  &:hover {
    border-color: ${theme.palette.secondary.main};
  }
`

const MenuButton = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`

const DesktopMenu = ({ routes }: DesktopMenuProps) => {
  const { path } = useRouteMatch()
  const { fm } = useI18nFormatter()

  return (
    <Wrapper data-testid={'DesktopMenu'}>
      <NavItems>
        {routes
          .filter((x) => x.headerLinkDesktop)
          .map((route) => (
            <li key={route.key}>
              <NavLink to={route.path} $isActive={path === route.path}>
                {fm(`${route.key}.title`)}
              </NavLink>
            </li>
          ))}
      </NavItems>

      <MenuButton>
        <LanguageSelect />
      </MenuButton>

      <MenuButton>
        <SettingsButton showIcon />
      </MenuButton>
    </Wrapper>
  )
}

export default DesktopMenu