import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import theme from 'theme'
import * as store from 'store'
import { ContextMenus } from 'store/types'
import { useClickOutside } from 'hooks'
import { ProjectContextMenu } from 'components/Project'
import { ArrowContextMenu } from 'components/Arrow'
import { CardContextMenu } from 'components/Card'

const Wrapper = styled.ul<{ x: number, y: number }>`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  background: ${theme.palette.primary.inverse};
  padding: 2px;
  margin: 0;
  list-style-type: none;
  cursor: pointer;
`

const contextMenus: { [k in ContextMenus]: React.FC<any> } = {
  ProjectContextMenu,
  ArrowContextMenu,
  CardContextMenu,
}

const ContextMenu = () => {
  const dispatch = store.useAppDispatch()
  const onDismiss = () => dispatch(store.hideContextMenu())
  const { ref } = useClickOutside(onDismiss)
  const { name, props } = useSelector(store.selectContextMenu)
  const C = name ? contextMenus[name] : null

  if (!C || !props) return null

  return (
    <Wrapper data-testid={'ContextMenu'} x={props.x} y={props.y} ref={ref}>
      <C {...props} />
    </Wrapper>
  )
}

export default memo(ContextMenu)
