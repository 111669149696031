import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StageState } from 'store/types'
import theme from 'theme'
import * as store from 'store'
import { calculateScrollCenter } from 'utils'
import { FormattedButton } from 'components/Button'

const BackToCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`

const LegalFooter = styled.div`
  position: absolute; 
  left: ${theme.spacing.unit / 2}px;
  right: ${theme.spacing.unit / 2}px;
  bottom: ${theme.spacing.unit}px;
  text-align: center;
  opacity: 0.6;
  pointer-events: none;
  cursor: inherit;
  mix-blend-mode: difference;
`

const StageFooter = () => {
  const dispatch = store.useAppDispatch()
  const stage = useSelector(store.selectStage)
  const dimensions = useSelector(store.selectDimensions)
  const isAnyCardVisible = useSelector(store.selectIsAnyCardVisible)
  const cards = useSelector(store.selectAllCards)

  const updateStage = (updates: Partial<StageState>) => dispatch(store.updateStage(updates))
  const onClickBackToContent = () => updateStage(calculateScrollCenter(cards, stage, dimensions))

  return (
    <>
      {!isAnyCardVisible &&
        <BackToCenter>
          <FormattedButton
            data-testid={'BackToContent'}
            $variant={'primaryInverse'}
            id={'Buttons.backToContent'}
            onClick={onClickBackToContent}
          />
        </BackToCenter>
      }

      <LegalFooter>
        <FormattedMessage id={'Legal.copyright'} tagName={'div'} />
        <FormattedMessage id={'Legal.warranty'} tagName={'div'} />
      </LegalFooter>
    </>
  )
}

export default memo(StageFooter)