import styled from 'styled-components'
import { FormattedMessage,  } from 'react-intl'
import { IoIosAdd } from 'react-icons/io'

import { LinkButton } from 'components/Button'

const AddIcon = styled(IoIosAdd)`
  margin: 0 5px 0 -5px;
`

const NewProjectButton = () => (
  <LinkButton data-testid={'NewProjectButton'} $variant={'primaryInverse'} to={'/projects/new'} aria-label={'New Project'} $borderless>
    <AddIcon />
    <FormattedMessage id={'Buttons.new'} tagName={'span'} />
  </LinkButton>
)

export default NewProjectButton
