import styled from 'styled-components'
import { useSelector } from 'react-redux'

import theme from 'theme'
import * as store from 'store'
import { FontSizes } from 'store/types'
import { useI18nFormatter } from 'hooks'
import { yesNoOptions } from 'utils'
import { FormattedButton } from 'components/Button'
import Form, { SelectField } from 'components/Form'

type FormValues = {
  name: string
  notes: string
}

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Title = styled.h2`
  border-bottom: 1px solid ${theme.palette.primary.xxlight};
  padding-bottom: ${theme.spacing.unit / 2}px;
`

const fontSizeOptions: { value: FontSizes, label: string }[] = [
  { value: '14', label: 'Extra Small --' },
  { value: '16', label: 'Small -' },
  { value: '18', label: 'Normal' },
  { value: '20', label: 'Large +' },
  { value: '22', label: 'Extra Large ++' },
]

const SettingsPanel = () => {
  const { fm } = useI18nFormatter()
  const dispatch = store.useAppDispatch()
  const settings = useSelector(store.selectSettings)

  const onChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target
    dispatch(store.setSetting({ name, value }))
  }
  const onSubmit = (values: FormValues) => { }

  const resetAppState = () => {
    if (window.confirm(fm('Settings.resetAppStateWarning'))) {
      store.ignoreLocalStorage()
      window.location.reload()
    }
  }

  return (
    <Wrapper
      enableReinitialize
      formName={'SettingsPanel'}
      initialValues={settings}
      onSubmit={onSubmit}
    >
      <div>
        <Title>{fm('Settings.title')}</Title>

        <SelectField
          name={'fontSize'}
          label={fm('Settings.fontSize')}
          options={fontSizeOptions}
          onChange={onChange}
        />

        <SelectField
          name={'showPreviewFeatures'}
          label={fm('Settings.showPreviewFeatures')}
          options={yesNoOptions}
          onChange={onChange}
        />
      </div>

      <div>
        <Title>{fm('Settings.developerSettings')}</Title>

        <SelectField
          name={'showDebugInfo'}
          label={fm('Settings.showDebugInfo')}
          options={yesNoOptions}
          onChange={onChange}
        />

        <FormattedButton id={'Settings.resetAppState'} $variant={'secondary'} onClick={resetAppState} />
      </div>
    </Wrapper>
  )
}

export default SettingsPanel