import styled from 'styled-components'

import theme from 'theme'
import { ContextMenuOption } from 'store/types'
import * as store from 'store'
import { FormattedButton } from 'components/Button'

const ContextMenuItem = styled.li`
  padding: 2px;
  min-width: ${theme.contextMenu.width}px;

  & > * {
    width: 100%;
    justify-content: flex-start;
  }
`

const GroupSeperator = styled.div`
  height: 1px;
  margin-top: 4px;
  background: ${theme.palette.primary.xlight};
`

const useContextMenu = (groups: Array<Array<ContextMenuOption>>) => {
  const dispatch = store.useAppDispatch()
  const hideContextMenu = () => dispatch(store.hideContextMenu())
  
  const onPointerUp = (fn: any) => (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    if (fn) fn()
    hideContextMenu()
  }

  return groups.map((group, groupIndex) => {
    return group.map((option, optionIndex) => (
      <ContextMenuItem key={option.id}>
        <FormattedButton
          $borderless
          $variant={'primary'}
          id={option.id}
          onPointerUp={onPointerUp(option.onClick)}
          disabled={option.disabled}
        />

        {
          optionIndex === group.length - 1 &&
          groupIndex < groups.length - 1 &&
          <GroupSeperator />
        }
      </ContextMenuItem >
    ))
  })
}

export default useContextMenu