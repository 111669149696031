import styled from 'styled-components'
import { Field as FormikField, useField } from 'formik'

import theme from 'theme'
import Field from './Field'

type TextFieldProps = {
  label: string
  name: string
} & any;

const Input = styled(FormikField)`
  width: 100%;
  padding: ${theme.spacing.unit / 3}px;
  margin: ${theme.spacing.unit / 3}px 0;
  color: ${theme.palette.primary.inverse};
  background: ${theme.palette.primary.dark};
  border: 1px solid ${({ $hasError }) => $hasError ? theme.palette.secondary.main : theme.palette.primary.xlight};
  font-size: 18px;
  font-weight: lighter;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${theme.palette.secondary.main};
  }

  &::selection {
    background: ${theme.palette.secondary.dark};
  }
`

const TextField = ({ label, name, ...props }: TextFieldProps) => {
  const [input, meta] = useField(name)
  const { form, onChange, onBlur, ...inputProps } = props
  const hasError = meta.error && meta.touched

  const handleChange = (event: React.ChangeEvent) => {
    input.onChange(event)
    if (onChange) onChange(event)
  }

  const handleBlur = (event: React.ChangeEvent) => {
    input.onBlur(event)
    if (onBlur) onBlur(event)
  }

  return (
    <Field {...meta} name={name} label={label}>
      <Input
        {...input}
        {...inputProps}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete={'off'}
        $hasError={hasError}
      />
    </Field>
  )
}

export default TextField
