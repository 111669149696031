import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { rgba } from 'polished'

import theme from 'theme'
import * as store from 'store'

const Wrapper = styled.rect`
  fill: ${rgba(theme.palette.secondary.dark, 0.1)};
  stroke: ${theme.palette.secondary.light};
  stroke-width: 2px;
`

const Selection = () => {
  const isSelecting = useSelector(store.selectIsSelecting)
  const selectionRect = useSelector(store.selectSelectionRect)

  if (!isSelecting) return null

  return <Wrapper {...selectionRect} />
}

export default memo(Selection)