import React from 'react'
import styled from 'styled-components'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

export type MobileMenuButtonProps = {
  isOpen: boolean
  onClick: React.EventHandler<any>
}

const Wrapper = styled.div`
  height: 24px;
  line-height: 0;
  cursor: pointer;
`

const MobileMenuButton = ({ isOpen, onClick }: MobileMenuButtonProps) => {
  const Icon = isOpen ? AiOutlineClose : AiOutlineMenu

  return (
    <Wrapper data-testid={'MobileMenuButton'} onClick={onClick} onKeyPress={onClick} role={'button'} tabIndex={0}>
      <Icon />
    </Wrapper>
  )
}

export default MobileMenuButton