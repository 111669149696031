import { FormattedMessage } from 'react-intl'

import { useTemplateLoader } from 'hooks'
import Async, { Loading } from 'components/Async'
import Page from 'components/Page'
import { NewProjectForm } from 'components/ProjectForm'

const NewProjectPage = () => {
  const templateLoader = useTemplateLoader()

  return (
    <Page>
      <FormattedMessage id={'NewProjectPage.title'} tagName={'h1'} />
      <FormattedMessage id={'ProjectDashboardPage.disclaimer'} tagName={'p'} />
      
      <Async fallback={<Loading />} isLoading={templateLoader.isLoading} error={templateLoader.error}>
        <NewProjectForm />
      </Async>
    </Page>
  )
}

export default NewProjectPage
