import styled from 'styled-components'
import { Field as FormikField, useField } from 'formik'

import { IOption } from 'types'
import theme from 'theme'
import Field from './Field'

type SelectFieldProps = {
  label: string
  name: string
  options: IOption[]
} & any

const Select = styled(FormikField)`
  width: 100%;
  padding: ${theme.spacing.unit / 3}px;
  margin: ${theme.spacing.unit / 3}px 0;
  color: ${theme.palette.primary.inverse};
  background: ${theme.palette.primary.dark};
  border: 1px solid ${({ $hasError }) => $hasError ? theme.palette.secondary.main : theme.palette.primary.xlight};
  font-size: 18px;
  font-weight: lighter;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${theme.palette.secondary.main};
  }
`

const SelectField = ({ label, name, options, ...props }: SelectFieldProps) => {
  const [input, meta] = useField(name)
  const { form, ...inputProps } = props
  const hasError = meta.error && meta.touched

  return (
    <Field {...meta} name={name} label={label}>
      <Select {...input} {...inputProps} as={'select'} $hasError={hasError}>
        {options.map((option: IOption) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Select>
    </Field>
  )
}

export default SelectField
