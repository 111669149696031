import { ICard, Dimensions, FlooredNumber } from 'types'
import { StageState } from 'store/types'

import { getClosestCardBounds } from './bounds'

export const normalizeScroll = (pos: number) => Math.floor(pos) as FlooredNumber

export const calculateScrollCenter = (
  cards: ICard[],
  stage: StageState,
  dimensions: Dimensions
): { x: FlooredNumber; y: FlooredNumber } => {
  if (!cards.length) {
    return {
      x: normalizeScroll(0),
      y: normalizeScroll(0),
    }
  }

  const [x1, y1, x2, y2] = getClosestCardBounds(cards, stage)
  const centerX = (x1 + x2) / 2
  const centerY = (y1 + y2) / 2

  return {
    x: normalizeScroll(centerX - dimensions.width / 2 / stage.zoom),
    y: normalizeScroll(centerY - dimensions.height / 2 / stage.zoom),
  }
}
