import { useMemo, memo } from 'react'

type TextProps = React.SVGProps<SVGTextElement> & {
  width?: number
  height?: number
  lineHeight?: number
  fontSize?: number
  verticalAlign?: string
  children: string
}

type CalculateLines = {
  maxWidth?: number
  maxHeight?: number
  lineHeight: number
  fontSize: number
  fontWeight: string | number
  text: string
}

const calculateWordWidths = (words: string, style: any = {}) => {
  // Calculate length of each word to be used to determine number of words per line
  var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  var text = document.createElementNS('http://www.w3.org/2000/svg', 'text')

  Object.assign(text.style, style)
  svg.appendChild(text)
  document.body.appendChild(svg)

  const wordsWithComputedWidth = words.split(/\s+/).map((word) => {
    text.textContent = word
    return { word, width: text.getComputedTextLength() }
  })

  text.textContent = '\u00A0' // Unicode space
  const spaceWidth = text.getComputedTextLength()

  document.body.removeChild(svg)

  return { wordsWithComputedWidth, spaceWidth }
}

const getLineWidth = (line: string) =>
  calculateWordWidths(line).wordsWithComputedWidth.reduce(
    (sum, value) => sum + value.width,
    0
  )

const calculateLines = ({
  maxWidth,
  maxHeight,
  lineHeight,
  fontSize,
  fontWeight,
  text,
}: CalculateLines) => {
  let lines = []
  let line = ''
  let lineWidth = 0

  const { wordsWithComputedWidth, spaceWidth } = calculateWordWidths(text, { lineHeight, fontSize, fontWeight, fontFamily: "Arial, Helvetica, sans-serif" })

  for (let i = 0; i < wordsWithComputedWidth.length; i++) {
    const { word, width } = wordsWithComputedWidth[i]
    const shouldWrap = maxWidth && (lineWidth + width + spaceWidth > maxWidth)
    const shouldTruncate = maxHeight && ((lines.length + 1) * lineHeight * fontSize >= maxHeight)
    let withWord = `${line}${word} `

    if (shouldWrap && shouldTruncate) {
      while (getLineWidth(withWord) > maxWidth!) {
        withWord = `${withWord.substring(0, withWord.length - 4)}...`
        line = withWord
      }
      break
    }

    if (shouldWrap) {
      lines.push(line)
      line = `${word} `
      lineWidth = width + spaceWidth
    } else {
      line = withWord
      lineWidth += width + spaceWidth
    }
  }

  lines.push(line)

  return lines
}

const Text = ({
  x,
  y,
  width,
  height,
  lineHeight = 1.2,
  fontSize = 18,
  fontWeight = 'normal',
  verticalAlign = 'baseline',
  children,
  ...props
}: TextProps) => {
  const lines = useMemo(() => calculateLines({
    maxWidth: width,
    maxHeight: height,
    lineHeight,
    fontSize,
    fontWeight,
    text: children,
  }), [width, height, lineHeight, fontSize, fontWeight, children])

  return (
    <text {...props} fontSize={fontSize} fontWeight={fontWeight}>
      {lines.map((line, index) => {
        const dy =
          verticalAlign === 'middle' && height
            ? `${Math.floor((height / (lines.length + 1)) * (index + 1) * lineHeight)}px`
            : `${Math.floor((index + 1) * lineHeight * fontSize)}px`

        return (
          <tspan key={`line-${index}`} x={x} y={y} dy={dy}>
            {line}
          </tspan>
        )
      })}
    </text>
  )
}

export default memo(Text)