import { Rect } from 'types'

export const isSameRect = (rect1: Rect, rect2: Rect) => {
  return (
    rect1.x === rect2.x &&
    rect1.y === rect2.y &&
    rect1.width === rect2.width &&
    rect1.height === rect2.height
  )
}
