import { rgba } from 'polished'

const cardBorderWidth = 5
const cardPadding = 10
const cardTitleHeight = 2 * 18 * 1.2 // 2 lines at 18px
const cardQuoteHeight = 4 * 16 * 1.2 // 4 lines at 16px
const cardImageHeight = 100
const cardWidth = 180
const cardHeight =
  cardBorderWidth +
  cardPadding +
  cardTitleHeight +
  cardPadding +
  cardQuoteHeight +
  cardPadding +
  cardImageHeight +
  cardPadding +
  cardBorderWidth

const theme = {
  palette: {
    primary: {
      xxxlight: '#ccc',
      xxlight: '#999',
      xlight: '#555',
      light: '#111',
      main: '#070707',
      dark: '#000',
      inverse: '#fff',
    },
    secondary: {
      light: '#f00',
      main: '#d00',
      dark: '#a00',
      inverse: '#fff',
    },
    disabled: {
      light: rgba('#333', 0.5),
      main: rgba('#111', 0.5),
    },
  },
  header: {
    height: 50,
  },
  footer: {
    height: 80,
  },
  commandBar: {
    height: 50,
  },
  grid: {
    unit: 45,
  },
  arrow: {
    strokeWidth: 1,
  },
  card: {
    width: cardWidth,
    height: cardHeight,
    borderWidth: cardBorderWidth,
    padding: cardPadding,
    titleHeight: cardTitleHeight,
    quoteHeight: cardQuoteHeight,
    imgHeight: cardImageHeight,
  },
  cardAction: {
    menuWidth: 200,
    actionSize: 40,
  },
  contextMenu: {
    width: 150,
  },
  projectList: {
    actionSize: 24,
  },
  donate: {
    qrcSize: 300,
  },
  field: {
    height: 40,
  },
  spacing: {
    unit: 35,
  },
  zIndex: {
    canvas: 1,
    commandBar: 1050,
    header: 1060,
    modal: 1070,
  },
  breakpoints: {
    xs: 500,
    sm: 700,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

export default theme
