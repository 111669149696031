import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import theme from 'theme'
import { useI18nFormatter } from 'hooks'
import { FormattedButton } from 'components/Button'
import Page from 'components/Page'

const ProductsHeading = styled.div`
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;
  padding: ${theme.spacing.unit / 2}px;

  @media (min-width: ${theme.breakpoints.lg}px) {
    max-width: ${theme.breakpoints.md}px;
  }
`

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
`

const SectionText = styled.div`
  flex: 1 ${theme.breakpoints.xs}px;
  margin: auto;
  padding: ${theme.spacing.unit}px;
  max-width: ${theme.breakpoints.sm}px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: ${theme.spacing.unit * 2}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: ${theme.spacing.unit * 3}px;
  }
`

const SectionTextHeader = styled.span`
  color: ${theme.palette.secondary.light};
  width: 100%;
  font-size: ${props => props.theme.fontSize * 2}px;
  padding-right: ${theme.spacing.unit / 2}px;
  
  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: ${props => props.theme.fontSize * 3}px;
  }
`

const SectionTextMessage = styled.span`
  color: ${theme.palette.primary.xxxlight};
  line-height: ${props => props.theme.fontSize / 8}; 

  @media (min-width: ${theme.breakpoints.md}px) {
    line-height:  ${props => props.theme.fontSize / 6}; 
  }
`

const Img = styled.img`
  flex: 1 ${theme.breakpoints.xs}px;
  border: 1px solid ${theme.palette.primary.inverse};
  width: 100%;
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;
`

const YoutubeEmbed = styled.div`
  flex: 1 ${theme.breakpoints.xs}px;
  max-width: ${theme.breakpoints.sm}px;
  margin: auto;

  & > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 ratio */
    height: 0;
    overflow: hidden;

    & > iframe {
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%;
    }
  }
`

const YoutubeButton = styled.div`
  padding: ${theme.spacing.unit}px 0;
`

const MakePlayingCardsLink = () => {
  window.open('https://www.makeplayingcards.com/sell/artofwarstrategies', '_blank')
}

const ProductsPage = () => {
  const { fm } = useI18nFormatter()

  return (
    <Page fluid>
      <ProductsHeading>
        <FormattedMessage id={'Products.Title'} tagName={'h1'} />
        <FormattedMessage id={'Products.intro1'} tagName={'p'} />
      </ProductsHeading>

      <Section>
        <YoutubeEmbed>
          <div>
            <iframe
              allowFullScreen
              title={'Art of War Strategies Card Deck Video'}
              src={'https://www.youtube.com/embed/gD4Kp-mR3kk'}
              frameBorder={0}
              allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
            />
          </div>
        </YoutubeEmbed>
        <SectionText>
          <SectionTextHeader>{fm('Products.Practice')}</SectionTextHeader>
          <SectionTextMessage>{fm('Products.Practice1')}</SectionTextMessage>
        </SectionText>
      </Section>

      <Section>
        <Img src={'images/FlashCardShot1.png'} alt={''} />
        <SectionText>
          <SectionTextHeader>{fm('Products.Purchase')}</SectionTextHeader>
          <SectionTextMessage>{fm('Products.Purchase1')}</SectionTextMessage>
          <YoutubeButton>
            <FormattedButton id={'Products.MPCLink'} $variant={'primaryInverse'} onClick={MakePlayingCardsLink} />
          </YoutubeButton>
        </SectionText>
      </Section>

      <ProductsHeading><FormattedMessage id={'Products.disclaimer'} tagName={'p'} /></ProductsHeading>
    </Page>

  )
}

export default ProductsPage
