import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ProjectDetailState } from 'store/types'
import theme from 'theme'
import * as store from 'store'
import Button from 'components/Button'
import Form, { SelectField, TextField } from 'components/Form'

type FormValues = {
  name: string
  template: string
}

const Fields = styled.div`
  padding: ${theme.spacing.unit / 2}px 0;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const AddProjectForm = () => {
  const history = useHistory()
  const dispatch = store.useAppDispatch()
  const templateOptions = useSelector(store.selectTemplateOptions)
  const templates = useSelector(store.selectTemplateEntities)

  const addProject = (values: ProjectDetailState) => dispatch(store.addProject(values))
  const onCancel = () => history.push('/projects')

  const onSubmit = async (values: FormValues) => {
    const { template, ...restValues } = values

    let project: ProjectDetailState
    if (template && templates[template]) {
      const { id, ...restTemplate } = templates[template]!
      project = store.getProjectDefault({ ...restTemplate, template, ...restValues })
    } else {
      project = store.getProjectDefault(restValues)
    }

    addProject(project)
    history.push(`/projects/${project.id}`)
  }

  return (
    <Form
      formName={'AddProjectForm'}
      initialValues={{ name: '', template: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Fields>
        <TextField
          required
          name={'name'}
          label={'Name'}
          placeholder={'Enter a name for the project'}
        />
        <SelectField
          name={'template'}
          label={'Template'}
          options={templateOptions}
        />
      </Fields>

      <Buttons>
        <Button
          data-testid={'AddProjectForm-cancel'}
          $variant={'secondary'}
          onPointerUp={onCancel}
        >
          <FormattedMessage id={'Buttons.cancel'} />
        </Button>

        <Button
          data-testid={'AddProjectForm-submit'}
          $variant={'primaryInverse'}
          type={'submit'}
        >
          <FormattedMessage id={'Buttons.submit'} />
        </Button>
      </Buttons>
    </Form>
  )
}

export default AddProjectForm