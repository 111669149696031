import { memo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  IoIosAddCircleOutline,
  IoIosArrowBack,
  IoIosUndo,
  IoIosRedo,
  IoMdGrid,
  IoMdApps,
  IoIosSave
} from 'react-icons/io'
import { MdPictureAsPdf } from 'react-icons/md'
import { GiCardPickup } from "react-icons/gi"

import { IToast } from 'types'
import theme from 'theme'
import * as store from 'store'
import { useI18nFormatter } from 'hooks'
import { ZoomPicker } from 'components/Zoom'

import CommandButton from './CommandButton'

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: ${theme.palette.primary.inverse};
  height: ${theme.commandBar.height}px;
  color: ${theme.palette.primary.main};
`

export const Section = styled.div`
  display: flex;
  height: ${theme.commandBar.height}px;
`

export const ProjectName = styled.div`
  padding: 0 ${theme.spacing.unit / 2}px;
  width: 250px;
  text-align: left;
  font-size: 16px;

  @media (max-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`

const CommandBar = () => {
  const { fm } = useI18nFormatter()
  const history = useHistory()
  const dispatch = store.useAppDispatch()
  const { showPreviewFeatures } = useSelector(store.selectSettings)
  const isGridSnapping = useSelector(store.selectGridIsSnapping)
  const isGridVisible = useSelector(store.selectGridIsVisible)
  const commandPanel = useSelector(store.selectCommandPanel)
  const projectName = useSelector(store.selectProjectName)
  const canSave = useSelector(store.selectCanSave)
  const canUndo = useSelector(store.selectCanUndo)
  const canRedo = useSelector(store.selectCanRedo)

  const addToast = (toast: Omit<IToast, 'id'>) => dispatch(store.addToast(toast))
  const showCommandPanel = (props: any) => dispatch(store.showCommandPanel(props))
  const hideCommandPanel = () => dispatch(store.hideCommandPanel())
  const saveProject = () => dispatch(store.saveProject())
  const printProject = () => dispatch(store.printProject())
  const setRandomQuoteIndexes = () => dispatch(store.generateRandomQuoteIndexes())

  const onClickBack = () => history.push('/projects')

  const onClickSaveProject = async () => {
    try {
      await saveProject()
      addToast({ text: fm('Toasts.successSave'), variant: 'success' })
    } catch (err) {
      addToast({ text: fm('Toasts.failedSave', { err: String(err) }), variant: 'error' })
    }
  }

  const onClickPrintProject = async () => {
    await printProject()
  }

  const onClickUndo = () => dispatch(store.undo())
  const onClickRedo = () => dispatch(store.redo())

  const onClickSuggestCard = () => {
    dispatch(store.suggestCard())
  }

  const onClickAddCard = (event: React.PointerEvent<any>) => {
    event.stopPropagation()
    setRandomQuoteIndexes()

    if (commandPanel.name === 'BaseCardCommandPanel') {
      hideCommandPanel()
    } else {
      showCommandPanel({ name: 'BaseCardCommandPanel', props: {} })
    }
  }

  const onClickSnapToGrid = () => {
    addToast({ text: `${isGridSnapping ? fm('Toasts.SnapOff') : fm('Toasts.SnapOn')}`, variant: 'success' })
    dispatch(store.toggleGridSnapping())
  }
 

  const onClickShowGrid = () => dispatch(store.toggleGridVisible())

  return (
    <Wrapper data-testid={'CommandBar'}>
      <Section>
        <CommandButton data-testid={'CommandBar-back'} onPointerUp={onClickBack} title={'Back to Dashboard'}>
          <IoIosArrowBack />
          <ProjectName>{projectName}</ProjectName>
        </CommandButton>
      </Section>

      <Section>
        {showPreviewFeatures && (
          <CommandButton data-testid={'CommandBar-print'} onPointerUp={onClickPrintProject} title={'Print Project'}>
            <MdPictureAsPdf />
          </CommandButton>
        )}
        <CommandButton data-testid={'CommandBar-save'} onPointerUp={onClickSaveProject} title={'Save Project'} disabled={!canSave}>
          <IoIosSave />
        </CommandButton>
        <CommandButton data-testid={'CommandBar-undo'} onPointerUp={onClickUndo} title={'Undo Changes'} disabled={!canUndo}>
          <IoIosUndo />
        </CommandButton>
        <CommandButton data-testid={'CommandBar-redo'} onPointerUp={onClickRedo} title={'Redo Changes'} disabled={!canRedo}>
          <IoIosRedo />
        </CommandButton>
      </Section>

      <Section>
        <CommandButton data-testid={'CommandBar-add-card'} onPointerUp={onClickAddCard} title={'Add Card'} isActive={commandPanel.name === 'BaseCardCommandPanel'}>
          <IoIosAddCircleOutline />
        </CommandButton>
        {showPreviewFeatures && (
          <CommandButton data-testid={'CommandBar-suggest-card'} onPointerUp={onClickSuggestCard} title={'Suggest Card'} isActive={false /*will be conditional if modal is in view*/}>
            <GiCardPickup />
          </CommandButton>
        )}
      </Section>

      <Section>
        <CommandButton data-testid={'CommandBar-snap-to-grid'} onPointerUp={onClickSnapToGrid} title={'Snap to Grid'} isActive={isGridSnapping}>
          <IoMdApps />
        </CommandButton>
        <CommandButton data-testid={'CommandBar-toggle-grid'} onPointerUp={onClickShowGrid} title={'Toggle Grid'} isActive={isGridVisible}>
          <IoMdGrid />
        </CommandButton>
      </Section>

      <Section>
        <ZoomPicker />
      </Section>
    </Wrapper>
  )
}

export default memo(CommandBar)