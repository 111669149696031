import { useState, useEffect, useRef } from 'react'

const useClickOutside = (callback?: any) => {
  const ref = useRef(null)
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    const onPointerDown = (event: MouseEvent) => {
      if (!(ref.current as any)?.contains(event.target)) {
        setHidden(true)
        callback && callback(event)
      }
    }

    document.addEventListener('pointerdown', onPointerDown)

    return () => {
      document.removeEventListener('pointerdown', onPointerDown)
    }
  })

  return { ref, hidden, setHidden }
}

export default useClickOutside
