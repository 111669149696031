import styled from 'styled-components'

import theme from 'theme'

const FieldLoading = styled.div<{ width: number }>`
  background: ${theme.palette.disabled.light};
  height: 21px;
  width: ${({ width }) => width}px;
  margin: ${theme.spacing.unit}px ${theme.spacing.unit / 2}px;
`

export default FieldLoading