import { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import { Update } from '@reduxjs/toolkit'
import styled from 'styled-components'

import { ICard, FlooredNumber } from 'types'
import theme from 'theme'
import * as store from 'store'
import { getCommonCardsBounds } from 'utils/bounds'
import { getOffsetPosition, getCardAtPosition } from 'utils/scene'
import { getPointFromEvent, buttons } from 'utils/mouse'

const { unit } = theme.grid

const Wrapper = styled.rect`
  fill: transparent;
  stroke: ${theme.palette.secondary.light};
  stroke-width: 2px;
  stroke-dasharray: 4;
  cursor: move;
`

const SelectedBoundingBox = () => {
  const dispatch = store.useAppDispatch()
  const isGridSnapping = useSelector(store.selectGridIsSnapping)
  const zoom = useSelector(store.selectZoom)
  const { stageX, stageY } = useSelector(store.selectStageCoord)
  const allCards = useSelector(store.selectAllCards)
  const selectedIds = useSelector(store.selectSelectedIds)
  const selectedCards = allCards.filter(x => selectedIds.includes(x.id))

  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const [isActive, setIsActive] = useState(false)
  const [isMoving, setIsMoving] = useState(false)

  const [x1, y1, x2, y2] = getCommonCardsBounds(selectedCards)

  const updateManyCards = (updates: Update<ICard>[], commitToHistory?: boolean) => dispatch(store.updateManyCards({ updates, commitToHistory }))
  const clearSelectionRect = () => dispatch(store.clearSelectionRect())
  const addSelected = (id: string) => dispatch(store.addSelected(id))
  const setSelected = (ids: string[]) => dispatch(store.setSelected(ids))
  const removeSelected = (id: string) => dispatch(store.removeSelected(id))

  const commitNewPosition = () => {
    const updates: Update<ICard>[] = selectedCards.map((card) => ({
      id: card.id,
      changes: {
        x: isGridSnapping ? Math.round(card.x / unit) * unit as FlooredNumber : card.x,
        y: isGridSnapping ? Math.round(card.y / unit) * unit as FlooredNumber : card.y,
      }
    }))

    updateManyCards(updates, true)
  }

  const onPointerDown = ({
    button,
    target,
    clientX,
    clientY,
    pointerId,
  }: React.PointerEvent<SVGGElement>) => {
    if (button !== buttons.left) return

    const bbox = (target as SVGGElement).getBoundingClientRect();
    (target as SVGGElement).setPointerCapture(pointerId)
    setIsActive(true)
    setOffset({ x: Math.floor((clientX - bbox.left) / zoom), y: Math.floor((clientY - bbox.top) / zoom) })
  }

  const onPointerUp = async (event: React.PointerEvent<SVGGElement>) => {
    if (event.button !== buttons.left) return

    if (isMoving) {
      commitNewPosition()
    } else {
      if (event.shiftKey || event.ctrlKey) {
        const pointer = getPointFromEvent(event)
        const card = getCardAtPosition(allCards, stageX + pointer.x / zoom, stageY + pointer.y / zoom)

        if (card) {
          if (selectedIds.includes(card.id)) {
            removeSelected(card.id)
          } else {
            addSelected(card.id)
          }
        } else {
          clearSelectionRect()
          setSelected([])
        }
      } else {
        clearSelectionRect()
        setSelected([])
      }
    }

    setIsMoving(false)
    setIsActive(false)
  }

  const onPointerMove = (event: React.PointerEvent<SVGGElement>) => {
    const { newX, newY, xOffset, yOffset } = getOffsetPosition(event, { x: x1, y: y1 }, offset, zoom)

    if (isActive && (newX !== x1 || newY !== y1)) {
      setIsMoving(true)

      const updates: Update<ICard>[] = selectedCards.map((card) => ({
        id: card.id,
        changes: {
          x: card.x + xOffset - offset.x as FlooredNumber,
          y: card.y + yOffset - offset.y as FlooredNumber
        }
      }))

      updateManyCards(updates)
    }
  }

  return (
    <Wrapper
      data-testid={'SelectionBoundingBox'}
      x={x1 - theme.spacing.unit / 2}
      y={y1 - theme.spacing.unit / 2}
      width={x2 - x1 + theme.spacing.unit}
      height={y2 - y1 + theme.spacing.unit}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
    />
  )
}

export default memo(SelectedBoundingBox)