import { FormattedMessage } from 'react-intl'

import Page from 'components/Page'

const LegalPage = () => {
  return (
    <Page>
      <FormattedMessage id={'LegalPage.terms.title1'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term1'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title2'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term2'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.term2.1'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title3'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term3'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title4'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term4'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title5'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term5'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title6'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term6'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title7'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term7'} tagName={'p'}/>
      <FormattedMessage id={'LegalPage.terms.title8'} tagName={'h2'} />
      <FormattedMessage id={'LegalPage.terms.term8'} tagName={'p'}/>
    </Page>
  )
}

export default LegalPage
