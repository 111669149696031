import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit'
import shortid from 'shortid'

import { IToast } from 'types'
import { RootState } from './rootReducer'

export const toastAdapter = createEntityAdapter<IToast>()
export const initialToastState = toastAdapter.getInitialState()

const toast = createSlice({
  name: 'toast',
  initialState: initialToastState,
  reducers: {
    addToast(state, { payload }: PayloadAction<Omit<IToast, 'id'>>) {
      toastAdapter.addOne(state, { ...payload, id: shortid.generate() })
    },
    removeToast: toastAdapter.removeOne,
  },
})

export const { addToast, removeToast } = toast.actions

export const toastsReducer = toast.reducer

export const toastSelectors = toastAdapter.getSelectors<RootState>((state) => state.toasts)
export const toastByIdSelector = (id: string | number) => (state: RootState) =>
  toastSelectors.selectById(state, id)
